.form {
    display: flex;
    flex-direction: column;
    width: 100%;

}

.item {
    width: 100%;
}
.button {
    width: 288px;
    max-width: 288px;
    justify-content: center;
    align-self: center;
}
.title {
    text-align: center;
    margin-bottom: 40px;
    text-transform: capitalize;
}
.controls {
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 28px;
}
.input {
    height: 48px;
}

.errorText {
    text-align: center;
    color: red;
}

.checkbox {
    margin-bottom: 0;
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;