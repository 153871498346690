.wrapper {
    border-radius: 12px;
    margin-top: 8px;
    width: 288px;
    background: var(--component-bg);
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    .menu {
        background: var(--component-bg);
        padding: 0;
        box-shadow: unset;
    }
    .btn {
        width: 100%;
        border-radius: 0px;
        svg {
            margin-right: 16px;
        }
        &:focus {
            color: unset;
            border-color: unset;
            background: none;
        }
    }
    .subTitle {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        margin: 16px 16px 4px 16px;
        color: var(--text-secondary);
    }
    .clearButton {
        justify-content: flex-start;
        font-size: 14px;
        padding-left: 20px;
        margin-top: 16px;
        svg {
            margin-right: 4px;
            width: 9px;
            height: 9px;
        }
    }
    .datepicker {
        background-color: transparent;
        border: 1px solid #B3B3B3;
        border-radius: 8px;
        margin: 0 16px;
        box-shadow: none;
        :global {
            .ant-picker-range-separator {
                font-size: 24px;
                color: var(--text-secondary);
            }
            .ant-picker-panel-container {
                box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.5);
                background: var(--component-bg);
                border-radius: 8px;
            }
            .ant-picker-panels {
                background: var(--component-bg);
            }
            .ant-picker-header {
                border-bottom: none;
                color: var(--text-primary);
            }
            .ant-picker-header-view {
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
            }
            .ant-picker-body {
                border-bottom: none;
            }
            .ant-picker-range-arrow {
                display: none;
            }
            .ant-picker-panel {
                border: none;
            }
            .ant-picker-input {
                border-radius: 8px;
                padding: 4px 8px 4px 8px;
                input {
                    color: var(--text-secondary);
                }
            }
            .ant-picker-active-bar {
                display: none;
            }
            .ant-picker-suffix span svg {
                color: var(--text-secondary);
            }
            .ant-picker-clear {
                background: var(--component-bg);
                span svg {
                    color: var(--text-secondary);
                }
            }
            .ant-picker-content {
                thead tr th {
                    color: #858585;
                }
            }
            .ant-picker-cell {
                color: var(--text-secondary);
            }
            .ant-picker-prev-icon::before, .ant-picker-next-icon::before, .ant-picker-super-prev-icon::before, .ant-picker-super-next-icon::before {
                color: #858585;
            }
            .ant-picker-super-prev-icon::after, .ant-picker-super-next-icon::after {
                color: #858585;
            }
            .ant-picker-cell:hover:not(.ant-picker-cell-in-view)
            .ant-picker-cell-inner, .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
            .ant-picker-cell-inner {
                background: rgba(179, 179, 179, 0.2);
                border-radius: 8px;
            }
            .ant-picker-cell-in-view {
                color: var(--text-primary);
            }
            .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
                background: rgba(179, 179, 179, 0.2);
            }
            .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
                background: rgba(179, 179, 179, 0.2);
            }
        }
    }
    :global {
        .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
            border-right: none;
        }
        .ant-menu-item {
            padding: 0;
            height: 48px;
            margin-bottom: 0;
            &:hover{ 
                color: var(--text-primary);
            }
        }
        .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected, .ant-menu-item:active {
            background: rgba(229, 229, 229, 0.1);
        }
    }
}





@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;