.table {
    :global {
        .ant-checkbox-input::after {
            content: '';
            width: 45px;
            height: 55px;
            position: absolute;
            top: -20px;
            right: -15px;
        }
        .ant-badge { 
            padding: 0 14px 0 14px;
        }  
        .ant-badge-status-text {
            color: #858585;
        }
        .ant-checkbox-checked .ant-checkbox-inner {
            background: #00D98B;
            border: transparent;
            &:after {
                display: block;
            }
        }
        .ant-table.ant-table-small .ant-table-expanded-row-fixed {
            margin: 8px -38px 8px 8px;
        }
        .ant-checkbox-indeterminate .ant-checkbox-inner::after {
            display: block;
        }
        .ant-checkbox-inner {
            border: 1px solid #969696;
            background-color: transparent;
        }
        .ant-checkbox-inner::after {
            transition: unset;
        }
        .ant-checkbox-checked::after {
            border-color: transparent;
        }
        .ant-table {
            font-family: 'OpenSans';
            font-size: 16px;
            background: transparent;
            .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
                content: none;
            }
            .ant-table-selection {
                padding-left: 14px;
            }
            .ant-table-column-title {
                flex: unset;
            }
            .ant-table-column-title > .ant-table-column-sorters > .ant-table-column-title {
                margin-right: 16px;
            }
            .ant-table-filter-column {
                justify-content: flex-start;
            }
            .ant-table-filter-trigger {
                margin: 0 0 0 16px;
                color: var(--text-primary);
                &:hover,
                &.active {
                    color: #00ffa2;
                }
            }
            .ant-table-column-sorter {
                color: var(--text-primary);
            }
            .ant-table-thead {
                tr {
                    th {
                        padding: 30px 15px 16px 0;
                        background: transparent;
                        color: var(--text-primary);
                        font-weight: 600;
                        font-size: 14px;
                        border-bottom: transparent;
                        .ant-table-column-sorters {
                            justify-content: flex-start;
                            .ant-table-column-title {
                                flex: unset;
                            }
                        }
                    }
                }
                .ant-table-cell.ant-table-cell-scrollbar {
                    display: none;
                }
            }
            .ant-table-tbody {
                tr.ant-table-row-selected > td {
                    background: var(--table-row-select);
                }
                td.ant-table-column-sort {
                    background: transparent;
                }
                tr {
                    background: transparent;
                    &:hover {
                        cursor: pointer;
                        td {
                            background: var(--table-row-hover);
                        }
                    }
                    td {
                        border-bottom: 1px solid rgba(179, 179, 179, 0.3);
                        color: var(--text-primary);
                        position: relative;
                        height: 56px;
                        padding: 8px 16px 8px 0;
                        &.ant-table-cell-row-hover {
                            background-color: var(--table-row-hover)
                        }
                        &:first-child {
                            width: 46px;
                            padding-left: 14px;
                            overflow: hidden;
                            color: var(--text-secondary);
                        }
                        &:last-child {
                            overflow: hidden;
                            &::after {
                                display: none;
                            }
                        }
                    }
                }
                .ant-table-placeholder {
                    height: calc(100vh - 400px);
                }
            }
        }
        .ant-badge-status-text {
            color: #858585;
        }
        .ant-badge.ant-badge-status.ant-badge-not-a-wrapper .ant-badge-status-dot {
            margin: 0 2px;
            align-items: center;
            justify-content: center;
        }
        .ant-checkbox-inner {
            background-color: inherit;
            border-color: #b3b3b3;
        }
        .ant-checkbox-indeterminate .ant-checkbox-inner::after {
            display: block;
        }
        .ant-checkbox-inner::after {
            transition: unset;
        }
        .ant-checkbox-checked .ant-checkbox-inner {
            background: #00d98b;
            border: 1px solid #00d98b;
            &:after {
                display: block;
            }
        }
        .ant-progress-outer {
            display: flex;
        }
    }
}
    
.buttonsRow {
    background: var(--component-bg);
    border: 1px solid var(--component-bg);
    border-radius: 8px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;

    .buttons {
        display: flex;
        padding: 0px;
    }

    .btn.ant-btn {
        padding: 6px 6px;
    }

    .progress {
        display: flex;
        justify-self: end;
        align-items: center;
        gap: 8px;
        :global {
            .ant-progress-outer {
                display: flex;
            }
        }
    }
    .progressText {
        color: var(--text-primary);
        font-size: 12px;
        line-height: 16px;
    }
}

.popconfirm {
    background: var(--component-bg);
    border-radius: 8px;
}
    
    
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;