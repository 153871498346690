.wrapper {
    max-width: 1176px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.corpTariffs {
    display: grid;
    grid-gap: 20px;
    @media screen and (min-width: @xs){
        grid-template-columns: 1fr;
    }
    @media screen and (min-width: @md) {
        grid-template-columns: 1fr 1fr;
    }
    @media screen and (min-width: @xl) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}
.persTariffs {
    display: grid;
    grid-gap: 20px;
    @media screen and (min-width: @xs){
        grid-template-columns: 1fr;
    }
    @media screen and (min-width: @md) {
        grid-template-columns: 1fr 1fr;
    }
}

@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;