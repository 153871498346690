.wrapper {
    background: #F8F8F8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: @xs){
        padding: 48px 16px
    }
    @media screen and (min-width: @md) {
        padding: 56px 32px;
    }
    @media screen and (min-width: @lg) {
        padding: 80px 20px;
    }
}

.content {
    max-width: 1176px;
    width: 100%;
    margin: auto;
}

.titleBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.title {
    margin-bottom: 16px;
    font-style: normal;
    color: #2C2E34;
    @media screen and (min-width: @xs){
        font-weight: 700;
        font-size: 28px;
        line-height: 32px;
    }
    @media screen and (min-width: @md) {
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
    }
    @media screen and (min-width: @lg) {
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
    }
}

.desc {
    font-style: normal;
    display: flex;
    align-items: center;
    text-align: center;
    color: #2C2E34;
    @media screen and (min-width: @xs){
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
    @media screen and (min-width: @md) {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
    }
    @media screen and (min-width: @lg) {
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
    }
}

.strategyBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 80px;
    margin-bottom: 160px;
    &:last-child {
        margin-bottom: 0;
    }
    @media screen and (min-width: @xs){
        flex-direction: column;
        margin-top: 56px;
        margin-bottom: 112px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    @media screen and (min-width: @md) {
        flex-direction: row;
        margin-top: 80px;
        margin-bottom: 160px;
        &:last-child {
            margin-bottom: 0;
        }
        &:nth-child(even) {
            flex-direction: row-reverse;
        }
    }
    @media screen and (min-width: @lg) {
        flex-direction: row;
        margin-top: 80px;
        margin-bottom: 160px;
        &:last-child {
            margin-bottom: 0;
        }
        &:nth-child(even) {
            flex-direction: row-reverse;
        }
    }
}

.descBlock {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 16px;
    position: static;
    background: #FEFEFE;
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.04);
    border-radius: 16px;
    @media screen and (min-width: @xs){
        width: 100%;
        margin-bottom: 32px;
    }
    @media screen and (min-width: @md) {
        width: 40%;
        margin-bottom: 0;
    }
    @media screen and (min-width: @lg) {
        width: 40%;
        margin-bottom: 0;
    }
}

.compare {

    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
    @media screen and (min-width: @xs){
        width: 100%;
        height: 100%;
    }
    @media screen and (min-width: @md) {
        width: 50%;
        height: 50%;
    }
    @media screen and (min-width: @lg) {
        width: 50%;
        height: 50%;
    }
}

.descTitle {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #2C2E34;
    margin-bottom: 7px;
}

.descText {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #858585;
}

.img {
    margin-bottom: 24px;
}


@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;