.modalWrapper {
    .modalText {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--text-secondary);
        padding-bottom: 24px;
    }
    .modalButton {
        width: 100%;
        height: 40px;
        &:first-child {
            margin-bottom: 16px;
        }
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;