.search {
    :global {
        .ant-btn > .ant-btn-loading-icon {
            color: @primary-color
        }
        .ant-btn.ant-btn-loading::before {
            background: transparent;
        }
    }
}

@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;