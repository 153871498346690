.wrapper {
    width: 100%;
    flex-grow: 1;
    overflow: hidden;
    & > div > div:first-child {
        overflow-x: hidden !important;
    }
    .row {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 48px;
        overflow-x: hidden;
    }
}




.col {
    @media screen and (min-width: 726px){
        min-width: 50%;
    }
    @media screen and (min-width: 1005px){
        min-width: 33.3%;
    }
    @media screen and (min-width: 1320px) {
        min-width: 25%;
    }
    @media screen and (min-width: 1600px) {
        min-width: 20%;
    }
    @media screen and (min-width: 1921px) {
        min-width: 16.6%;
    }
}

@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;