.buttonEdit, .buttonSave, .buttonCancle {
    display: flex;
    width: 32px;
    justify-content: center;
    transition: 0.3s;
    color: var(--text-primary);
}

.buttonSave, .buttonCancle {
    background: var(--component-bg);
    border-color: var(--component-bg);
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover, &:focus, &:active {
        background: var(--component-bg);
        border-color: var(--component-bg);
    }
}

.buttonEdit {
    border-color: transparent;
    background: transparent;
    &:hover, &:focus, &:active {
        background: var(--component-bg);
        border-color: var(--component-bg);
        color: var(--text-primary);
    }

    &Active {
        border-color: rgba(0, 202, 129, 1);
        background: var(--component-bg);
        &:hover, &:focus, &:active {
            border-color: rgba(0, 202, 129, 1);
        }
    }
}

.buttonSave {
    :global {
        .anticon {
            line-height: 0;
        }
    }

    &:hover, &:focus {
        background: rgba(0, 202, 129, 1);
        border-color: rgba(0, 202, 129, 1);
    }
}
.buttonCancle {
    &:hover {
        background: rgba(248, 98, 98, 1);
        border-color: rgba(248, 98, 98, 1);
    }
}

.disabled {
    opacity: 0.5;
    border: 1px solid transparent;
    background: transparent;
    cursor: not-allowed;
    &::after {
        all: unset;
    }
    &:hover, &:focus, &:active {
        background: none;
        border: 1px solid transparent;
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;