.buttonHint {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--hint-icon-bg);
    cursor: pointer;
}

.hintTooltip {
    :global {
        .ant-tooltip-content .ant-tooltip-inner{
            text-align: start;
        }
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;