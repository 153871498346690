.wrapper {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    .info {
        display: flex;
        gap: 16px;
        align-items: flex-end;
        .title {
            font-size: 20px;
            line-height: 28px;
            font-weight: 600;
            color: var(--text-primary);
        }
        .usersCount { 
            font-size: 16px;
            line-height: 24px ;
            color: var(--text-secondary);
        }
    }
    .control {
        display: flex;
        align-items: center;
        gap: 10px;
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;