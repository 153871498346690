.header {
    display: flex;
    justify-content: center;
    top: 0;
    width: 100%;
    height: 56px;
    background: #FEFEFE;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.16);
    padding: 0 20px 0 20px;
}

.transparentHeader {
    display: flex;
    justify-content: center;
    top: 0;
    width: 100%;
    height: 56px;
    background: transparent;
    padding: 0 20px 0 20px;
}

.content {
    max-width: 1176px;
    margin: auto;
    width: 100%;
    display: flex;
}

.row {
    width: 100%;
    &:global {
        & > .ant-col {
            display: flex;
            align-items: center;
        }
    }
    .menuCol {
        display: flex;
        .links {
            display: flex;
            flex-direction: row;
            .link {
                position: relative;
                margin-right: 40px;
                color: #858585;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                &:hover {
                    color: #080808;
                }
            }
            .link::after {
                content: '';
                display: block;
                width: 40px;
                border-bottom: 2px solid transparent;
            }
            .link:hover:after {
                content: '';
                display: block;
                width: 40px;
                border-bottom: 2px solid #00CA81;
            }
            @media screen and (min-width: @xs){
                display: none;
            }
            @media screen and (min-width: @md) {
                display: none;
            }
            @media screen and (min-width: @lg) {
                display: flex;
            }
        }

        .buttons {
            display: flex;
            gap: 10px;
            @media screen and (min-width: @xs){
                display: none;
            }
            @media screen and (min-width: @md) {
                display: none;
            }
            @media screen and (min-width: @lg) {
                display: flex;
            }
        }
        
        .menuButton {
            padding: 4px 0;
            @media screen and (min-width: @xs){
                display: block;
            }
            @media screen and (min-width: @md) {
                display: block;
            }
            @media screen and (min-width: @lg) {
                display: none;
            }
        }
    }
}

.strokeButton {
    color: #00CA81;
    border: 1px solid #00CA81;

}

.logoCol {
    display: flex;
    align-items: center;
}

.title {
    color: #2C2E34;
}

.drawer {
    .drawerLinks {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        a {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #858585;
            margin-bottom: 48px;
        }

    }
    .drawerButtons {
        display: flex;
        flex-direction: column;
        gap: 12px;
        min-width: 343px;
        .drawerButton, .link.drawerButton{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
        }
    }
    :global {
        .ant-drawer-header {
            flex-direction: row-reverse;
            border-bottom: none;
        }
        .ant-drawer-header-title {
            flex-direction: row-reverse;
        }
        .ant-drawer-body {
            display: flex;
            align-items: center;
        }
    }
}

@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;