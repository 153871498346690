.wrapper {
    padding: 24px 24px 0 24px;
    .title {
        font-weight: 600;
        font-size: 28px;
        line-height: 32px;
        margin: 0 0 24px 0;
    }
    .tabName {
        display: flex;
        .docsCount {
            border-radius: 23px;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 19px;
            margin-left: 8px;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: var(--table-tabs-name-count-bg, rgba(0, 217, 139, 0.06));
            color: var(--table-tabs-name-count-color, #00CA81);
        }
    }
    :global {
        .ant-tabs > .ant-tabs-nav {
            margin: 0;
            margin-bottom: 20px;
            &::before {
                display: none;
            }
        }
        .ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav {
            align-items: flex-start;
        }
        .ant-tabs-extra-content {
            padding-top: 6px;
        }
        .ant-tabs-tab {
            padding: 12px 0 21px 0;
        }
        .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
            height: 3px;
            border-radius: 3px;
        }
        .ant-tabs-content {
            height: calc(100vh - 205px);
            overflow-y: scroll;
            &::-webkit-scrollbar {
                background-color: transparent;
            }
            &::-webkit-scrollbar-thumb {
                background-clip: padding-box;
                border-radius: 10px;
                border: 3px solid transparent;
                background-color: #B3B3B3;
            }
            &::-webkit-scrollbar-track {
                border-radius: 10px;
                background-color: transparent;
            }
        }
        .ant-tabs-tab {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            color: var(--table-tabs-name-color);
        }
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
            color: var(--electric-green);
            text-shadow: 0 0 1px var(--electric-green);
        }

    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;