.buttonStylesOverride :global .ant-btn.btn.ant-btn-lg {
    transition: 0.3s;
    font-weight: 600;
    background: var(--component-bg);
    border-color: var(--component-bg);
    justify-content: center;
    color: var(--text-primary);
    .anticon {
        line-height: 0;
    }
    .anticon,
    svg path {
        transition: 0s;
    }
}


.buttonStylesOverride :global .ant-btn.btn.ant-btn-lg:focus,
.buttonStylesOverride :global .ant-btn.btn.ant-btn-lg:hover,
.buttonStylesOverride :global .ant-btn.btn.ant-btn-lg:active {
    svg {
        transition: 0.1s;
        stroke: rgba(0, 202, 129, 1);
    }
    color: rgba(0, 202, 129, 1);
    border-color: rgba(0, 202, 129, 1);
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;