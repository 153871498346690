.popover {
    padding: 0;
    width: 264px;
    height: 276px;
    .empty {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: var(--text-primary);
        padding: 8px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .content {
        height: 174px;
        .type {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: var(--text-primary);
            padding: 8px;

        }
        .entities {
            height: 132px;
            overflow-y: auto;
            overflow-x: hidden;
            color: var(--text-primary);
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            & ul {
                padding-left: 28px;
                & li {
                    padding: 8px;
                }
            }
            &::-webkit-scrollbar {
                background-color: transparent;
            }
            &::-webkit-scrollbar-thumb {
                background-clip: padding-box;
                border-radius: 10px;
                border: 3px solid transparent;
                background-color: #B3B3B3;
            }
            &::-webkit-scrollbar-track {
                border-radius: 10px;
                background-color: transparent;
            }
        }
    }
    :global {
        .ant-popover-arrow {
            display: none;
        }
        .ant-popover-inner .ant-popover-inner-content {
            padding: 0;
        }
        
        .ant-popover-inner {
            padding: 4px;
        }

        .ant-popover-content .ant-popover-inner {
            height: 100%;
            & .ant-popover-inner-content {
                height: 100%;
            }
        }

        .ant-popover-content, .ant-popover-content .ant-popover-inner {
            border-radius: 8px;
            background-color: var(--component-bg);
            box-shadow: 0px 0px 12px -6px rgba(0, 0, 0, 0.13);
            height: 100%;
        }
    }
}

@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;