.wrapper {
    display: flex;
    align-content: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.title {
        font-weight: 700;
        color: var(--text-primary);
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 0;
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;