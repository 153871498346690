.wrapper {
    display: flex;  
    min-height: 100vh;
    padding: 40px;
    flex-direction: column;
    background-color: #F5F5F6;
    .content {
        max-width: 1280px;
        align-self: center;
        padding: 40px;
    }
}

@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;