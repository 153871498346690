.grid {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    height: 100%;
    background: #858585;
    display: flex;
    flex-direction: row;
}

.ViewPDF {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 30px 0 30px;
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;