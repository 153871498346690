.pagination {
    :global {
        .ant-pagination {
            gap: 8px;
        }
        .ant-pagination-mini .ant-pagination-total-text {
            color: var(--text-primary);
            font-size: 14px;
            line-height: 22px;
        }
        .ant-pagination-item-link {
            color: var(--text-primary);
            &:hover {
                color: #00a369;
            }
        }
        .ant-pagination.ant-pagination-mini .ant-pagination-item {
            height: 32px;
            width: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 0s;
        }
        .ant-pagination-item > a {
            color: var(--text-primary);
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            &:hover {
                color: var(--electric-green);
            }
        }
        .ant-pagination-item-active {
            border-radius: 8px;
            background: rgba(255, 255, 255, 0.05);
            border: 1px solid var(--electric-green);
        }
        .ant-table-pagination.ant-pagination {
            align-items: center;
        }
        .ant-pagination.mini .ant-pagination-disabled button:disabled {
            color: transparent;
        }
        .ant-pagination-jump-next .ant-pagination-item-ellipsis,
        .ant-pagination-jump-prev .ant-pagination-item-ellipsis  {
            color: rgb(200, 200, 200);
        }
        .ant-pagination.ant-pagination-mini .ant-pagination-options {
            margin-left: 8px;
        }
        .ant-pagination-options {
            .ant-select-selector {
                border: var(--pagination-border);
                background-color: inherit;
                color: var(--text-primary);
                border-radius: 8px;
                width: 59px;
                height: 32px !important;
                display: flex;
                align-items: center;
                box-shadow: none !important;
            }
            .ant-select-open {
                .ant-select-selector {
                    border: 1px solid #00CA81;
                    box-shadow: none;
                }
                
            }
            .ant-select-arrow {
                color: var(--text-primary);
            }
            .ant-select-dropdown {
                background: var(--component-bg);
                padding: 4px;
                width: 59px;

                .ant-select-item-option {
                    height: 32px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 22px;
                    background: var(--component-bg);
                    color: var(--text-primary);
                    padding: 4px 8px;
                    &:not(:last-child) {
                        margin-bottom: 4px;
                    }

                }
                .ant-select-item-option-selected {
                    background: rgba(229, 229, 229, 0.2);
                    border: 1px solid rgba(254, 254, 254, 0.2);
                }
                .ant-select-item-option-active {
                    background: rgba(255, 255, 255, 0.1);
                }
            }
        }
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;