.defaultToolTip {
    :global {
        .ant-tooltip-content {
            font-family: "OpenSans";
            padding: 4px ;
            .ant-tooltip-arrow {
                .ant-tooltip-arrow-content {
                    --antd-arrow-background-color: var(--tooltip-bg);
                }
                .ant-tooltip-arrow-content::before {
                    background-color: var(--tooltip-bg);
                } 
            }
            
            .ant-tooltip-inner {
                padding: 4px 8px;
                background-color: var(--tooltip-bg);
                text-align: justify;
                border: unset;
                color: var(--tooltip-color);
                white-space: wrap;
                overflow-wrap: anywhere;
            }
        }
    }
}
.bottom :global .ant-tooltip-content .ant-tooltip-arrow {
    top: 7px;
}
.right :global .ant-tooltip-content .ant-tooltip-arrow {
    bottom: 0;
    left: 7px;
}
.lineTooltip {
    max-width: max-content;
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;