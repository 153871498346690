.wrapper {
    :global {
        .ant-row.ant-row-space-between .ant-row:last-child .ant-col:last-child {
            padding: 0 !important;
            margin-left: 16px;
        }
        .ant-row.ant-row-space-between .ant-row:last-child .ant-col:last-child button {
            display: flex;
            justify-content: center;
        }
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;