.customInput {
    margin-top: 10px;
    display: flex;
    :global {
        .ant-btn:first-child {
            box-shadow: none !important;
            border: 0;
            width: min-content;
            border-radius: 8px 0px 0 8px;
            padding: 8px 16px;
        }
        .ant-btn:last-child {
            box-shadow: none !important;
            border: 0;
            width: min-content;
            border-radius: 0 8px 8px 0;
            padding: 8px 16px;
        }
        .ant-btn:first-child::after, 
        .ant-btn:last-child::after {
            display: none;
        }
        .ant-input {
            text-align: center;
            border: 0;
            border-radius: 0;
        }
        .ant-input::-webkit-outer-spin-button,
        .ant-input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        }
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;