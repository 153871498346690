.wrapper {
    color: var(--text-primary);
    display: flex;
    :global {
        .ant-checkbox-disabled {
            & + span {
                color: inherit;
            }
        }
        .ant-checkbox {
            margin-right: 20px;
        }
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;