.menu {
    width: 48px;
    background: transparent;
    border: unset;
    :global {
        .ant-menu-item-group-title {
            display: none;
        }
        .ant-menu-title-content {
            width: 100%;
            height: 100%;
        }
        .ant-menu-item.ant-menu-item-only-child {
            justify-content: center;
            margin: 0;
            padding: 0;
            width: 100%;
            &:active {
                background: rgba(254, 254, 254, 0.1);
            }
        }
        .ant-menu-item, .ant-menu-item a {
            color: var(--text-primary);
            &:hover {
                color: @primary-color;
            }
        }
        .ant-menu-item-selected, .ant-menu-item-selected a {
            color: @primary-color;
        }
        .ant-menu-item-group-list {
            display: flex;
        }
    }
}
.wrapper {
    color: var(--text-primary);
    :global {
        .ant-menu:not(.ant-menu-horizontal) {
            .ant-menu-item-selected {
                background-color: transparent;
            }
        }
        .ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
            left: 0;
            right: unset;
            border-right: 2px solid #00D98B;
        }
        .ant-menu-inline.ant-menu-root .ant-menu-item > *, .ant-menu-inline.ant-menu-root .ant-menu-submenu-title > * {
            justify-content: center;
            flex: none;
        }
    }
}

.sidebarContent {
    display: flex;
    flex-grow: 1;
    padding: 40px 0 32px 0;
    height: calc(100% - 48px);
    flex-direction: column;
    justify-content: space-between;
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;