.wrapper {
    background: #2C2E34;
    display: flex;
    justify-content: center;
    @media screen and (min-width: @xs){
        padding: 48px 16px;
    }
    @media screen and (min-width: @md) {
        padding: 56px 32px;
    }
    @media screen and (min-width: @lg) {
        padding: 80px 20px;
    }
}

.content {
    max-width: 1176px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.title {
    text-align: center;
    color: #FEFEFE;
    margin-bottom: 56px;
    @media screen and (min-width: @xs){
        font-weight: 700;
        font-size: 28px;
        line-height: 32px;
    }
    @media screen and (min-width: @md) {
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
    }
    @media screen and (min-width: @lg) {
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
    }
}
.img {
    width: 100%;
}

.corpTariffs {
    display: grid;
    grid-gap: 20px;
    @media screen and (min-width: @xs){
        grid-template-columns: 1fr;
    }
    @media screen and (min-width: @md) {
        grid-template-columns: 1fr 1fr;
    }
    @media screen and (min-width: @xl) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}
.persTariffs {
    display: grid;
    grid-gap: 20px;
    @media screen and (min-width: @xs){
        grid-template-columns: 1fr;
    }
    @media screen and (min-width: @md) {
        grid-template-columns: 1fr 1fr;
    }
}

.radio {
    padding: 4px;
    border-radius: 29px;
    background-color: rgba(65, 66, 72, 1);
    height: 46px;
    display: flex;
    width: fit-content;
    margin-bottom: 32px;
    .radioButton {
        color: rgba(254, 254, 254, 1);
        font-size: 14px;
        line-height: 20px;
        border: none;
        border-radius: 26px;
        background-color: transparent;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 168px;
        .radioDisabled {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
        }
        .icon {
            display: flex;
            height: 100%;
            align-items: center;
        }
        &:focus-within {
            box-shadow: unset;
        }
        &:not(:first-child)::before {
            display: none;
        }
        &:not(:last-child) {
            margin-right: 4px;
        }
    }

    :global {
        .ant-radio-button-wrapper {
            font-weight: 600;
            &:hover {
                background-color: rgba(254, 254, 254, 0.08);
            }
        }

        .ant-radio-button-wrapper-checked {
            background: rgba(0, 202, 129, 1);
            &:hover {
                background: rgba(0, 202, 129, 1);
                color: rgba(254, 254, 254, 1);
            }
        }

        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
            z-index: 0;
        }
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;