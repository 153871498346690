.wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    .loader {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        background: transparent;
        width: 100%;
    }
    .dragger {
        flex-grow: 1;
        display: flex;
        align-items: center;
        border: none;
        background: transparent;
        padding: 0 20px;
        height: 55%;
        width: 100%;
    }
    :global {
        .ant-drawer-header {
        border-bottom: none;
        }
        .ant-upload-list-item-info {
            color: var(--text-primary);
            .anticon {
                color: var(--text-primary);
            }
            &:hover {
                background: transparent;
            }
        }
        .ant-drawer-header {
            background: var(--component-bg);
        }
        .ant-drawer-body {
            padding-top: 70px;
            background: var(--component-bg);
        }
        .ant-drawer-close {
            color: var(--text-secondary);
            left: 0;
            right: unset;
        }
        .ant-upload.ant-upload-drag p.ant-upload-text {
            color: var(--text-primary);
        }
        .ant-upload.ant-upload-drag p.ant-upload-hint {
             color: var(--text-secondary);
        }
        .ant-upload-list {
            margin-top: 10px;
            overflow-y: auto;
            height: 30%;
            min-height: 96px;
            &::-webkit-scrollbar {
                width: 0.5em;
                height: 0.5em;
            }
            &::-webkit-scrollbar-thumb {
                background-color: rgba(255, 255, 255, .1);
                border-radius: 3px;
                &:hover {
                    background: rgba(255, 255, 255, .2);
                }
            }   
        }
    }
}
.contentWrapper {
    display: block;
    height: calc(100% - 110px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    & > span { 
        height: 100%;
        display: flex;
        flex-direction: column;
    }
}
.button {
    width: 288px;
    margin: 16px 0 -20px 0;
    position: static;
}

@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;