.empty {   
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    :global {
        .ant-empty-image {
            height: 200px;
            width: 200px;
        }
        .ant-empty-image:empty {
            height: 0;
            margin: 0;
        }
    }
}
.bottomPadding {
    padding-bottom: 5%;
}
.typeSmallText {
    color: #858585;
    max-width: 250px;
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;