.container {
    margin: auto;
    width: 100%;
    display: block;
    box-sizing: border-box;
    margin-top: unset;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 24px;
}

@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;