.wrapper {
    padding: 18px 16px;
    background: var(--component-bg);
    border-radius: 8px;
    .content {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
    .checkbox {
        color: var(--text-primary);
        font-size: 16px;
        display: flex;
        gap: 3px;
        :global {
            .ant-checkbox-inner {
                background-color: transparent;
            }
            .ant-checkbox-checked .ant-checkbox-inner {
                background: #00d98b;
                border:1px solid #00d98b;
                &:after {
                    display: block;
                }
            }
        }
        &Description {
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;