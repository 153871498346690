.CreateTemplateButton {
    display: flex;
    width: 32px;
    justify-content: center;
    transition: 0.3s;
    color: var(--text-primary);
}

.CreateTemplateButton {
    border-color: transparent;
    background: transparent;
    &:hover, &:focus, &:active {
        background: var(--component-bg);
        border-color: var(--component-bg);
        color: var(--text-primary);
    }

    &Active {
        border-color: rgba(0, 202, 129, 1);
        background: var(--component-bg);
        &:hover, &:focus, &:active {
            border-color: rgba(0, 202, 129, 1);
        }
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;