.switch {
    :global {
        .ant-space-item {
            color: var(--text-secondary);
        }
        .ant-switch-handle {
            width: 16px;
            height: 16px;
            top: 3px;
            left: 4px;
        }
        .ant-switch-checked .ant-switch-handle {
            left: calc(100% - 16px - 4px)
        }
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;