.pieStat {
    padding: 16px;
    gap: 8px;
    background: var(--component-bg);
    border: 1px solid var(--component-bg);
    border-radius: 16px;
    height: 212px;
    width: 40%;
    &:not(:last-child) {
        margin-right: 16px;
    }
    .title {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: var(--text-secondary);
    }
    .pie {
        display: flex;
        height: 88%;
        align-items: center;
        justify-content: flex-start;
    }
    .pieInfo {
        flex: 1;
        width: 50%;
        &EmptyText {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;

            margin-left: 20px;
            color: var(--text-secondary);
        }
        .subInfo {
            display: flex;
            justify-content: space-between;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            // line-height: 16px;
            .name {
                display: block;
                width: 90%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            color: var(--text-primary);
            .count {
                font-weight: 600;
            }
        }
    }

}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;