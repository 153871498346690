.titleBlock {
    display: flex;
    gap: 12px;
    align-items: center;
    height: 40px;
}

.title {
    color: var(--text-primary);
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;