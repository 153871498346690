.buttonSberID {
    :global {
        .sbid-button {
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--sber-button-content-color);
            font-size: 16px;
            width: 100%;
            background: var(--sber-button-bg); // Цвет из pdf sberID не из дизайна
            padding: 8px 16px;
            gap: 8px;
            border-radius: 8px;
            transition: 0.15s;
        }
        .sbid-button:hover {
            background-color: var(--sber-button-bg-hover); // Цвет ховера используемый в онлайн подписи и дом клике
        }
        .sbid-button .sbid-button__logo svg {
            width: 24px;
            height: 24px;
            fill: var(--sber-button-content-color);
        }
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;