.wrapper {
    margin-top: 56px;
    padding: 96px 0 48px 0;
    background-image: url(./Images/Background.svg);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    background-position: 50% 100%;
    
}

.content {
    max-width: 1176px;
    margin: 0 auto;
}

.info {
    padding: 0 20px 0 20px;
    @media screen and (min-width: @xs){
        display: flex;
        flex-direction: column;
    }
    @media screen and (min-width: @md) {
        display: flex;
        flex-direction: row;
    }
    @media screen and (min-width: @lg) {

    }
}

.imgMobile {
    @media screen and (min-width: @xs){
        font-size: 32px;
        line-height: 40px;
    }
    @media screen and (min-width: @md) {
        font-size: 32px;
        line-height: 40px;
    }
    @media screen and (min-width: @lg) {
        display: none;
    }
}

.img {
    position: absolute;
    top: 152px;
    left: 60%;
    @media screen and (min-width: @xs){
        display: none;
    }
    @media screen and (min-width: @md) {
        display: none;
    }
    @media screen and (min-width: @lg) {
        display: block;
    }
}

.title {
    margin-bottom: 16px;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    color: #2C2E34;
    @media screen and (min-width: @xs){
        font-size: 32px;
        line-height: 40px;
    }
    @media screen and (min-width: @md) {
        font-size: 32px;
        line-height: 40px;
    }
    @media screen and (min-width: @lg) {
        font-size: 40px;
        line-height: 48px;
        width: 60%;
    }
}



.subtitle {
    font-style: normal;
    font-weight: 400;
    color: #2C2E34;
    margin-bottom: 40px;
    @media screen and (min-width: @xs){
        font-size: 16px;
        line-height: 24px;
    }
    @media screen and (min-width: @md) {
        font-size: 16px;
        line-height: 24px;
    }
    @media screen and (min-width: @lg) {
        font-size: 20px;
        line-height: 28px;
    }
}

.buttons {
    display: flex;
    margin-bottom: 76px;
    .demoButton {
        @media screen and (min-width: @xs){
            display: none;
        }
        @media screen and (min-width: @md) {
            display: none;
        }
        @media screen and (min-width: @lg) {
            display: flex;
        }
        &:hover {
            background: linear-gradient(180deg, #00A86B -25.89%, #137250 123.21%);
        }
    }
}

.blocks {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    @media screen and (min-width: @xs){
        overflow-x: unset;
        overflow-y: hidden;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    @media screen and (min-width: @md) {
        overflow: unset;
    }
    @media screen and (min-width: @lg) {
        overflow: unset;
    }
    .mainBlock {
        max-width: 248px;
        background: linear-gradient(135deg, #3B3F44 13%, #2E3036 87.5%);
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
        border-radius: 16px;
        margin-right: 16px;
        display: flex;
        flex-direction: column;
        padding: 32px;
        color: #FEFEFE;
        margin-left: 20px;
        .blockText {
            margin-top: 16px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
        }
    }
    .subBlock {
        max-width: 232px;
        min-width: 128px;
        background: #FEFEFE;
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
        border-radius: 16px;
        margin-right: 16px;
        display: flex;
        flex-direction: column;
        padding: 16px;
        color: #080808;
        .blockText {
            margin-top: 16px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
        }
    }
}

@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;