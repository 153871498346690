.toolTip {
    max-width: 180px;
    button {
        padding: 8px 12px;
    }
    button svg {
        margin-right: 8px;
    }
    .disabled {
        pointer-events: none;
        background: var(--button-stroke-disabled-bg);
        border: var(--button-stroke-disabled-border);
        color: var(--button-stroke-disabled-color);
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;