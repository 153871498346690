.wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.buttonBlock {
    display: flex;
    gap: 8px;
}

.subHeader {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    .filterButton {
        .filterCircle, .filteredCircle {
            width: 8px;
            height: 8px;
            margin-right: 16px;
            border-radius: 50%;
        }
        .filterCircle {
            background: #858585;
        }
        .filteredCircle {
            background: #00D98B;
        }
    }
    :global {
        .ant-dropdown-open {
            border: 1px solid #858585;
            background: var(--component-bg);
            color: var(--text-primary);
            box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.24);
            &:hover {
                border: 1px solid #858585 !important;
            }
            .anticon {
                transform: rotate(180deg);
            }
        }
        .anticon {
            transition: transform 0.3s;
        }
    }
}



.drawer {
    :global {
        .ant-drawer-header {
            border-bottom: none;
        }
        .ant-drawer-header {
            background: linear-gradient(115.09deg, #3B3F44 3.38%, #2E3036 92.1%);
        }
        .ant-drawer-body {
            padding-top: 70px;
            background: linear-gradient(115.09deg, #3B3F44 3.38%, #2E3036 92.1%);
        }
        .ant-drawer-close {
            color: var(--text-secondary);
            left: 0;
            right: unset;
        }
    }
}

@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;