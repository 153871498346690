.selectedGroup {
    background: var(--sidebar-btn-hover);
}

.sidebarItem {
    color: var(--text-primary);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        background-color: var(--sidebar-btn-hover);
    }
}

.sidebarItemActive {
    background-color: var(--sidebar-chosen-menu-bg);
}

.tooltip {
    padding-left: 0;
}

.sidebarItemDisabled {
    opacity: 0.5;
    pointer-events: none;
}

.linkActive {
    &:after {
        content: '';
        width: 3px;
        height: 40px;
        border-radius: 3px;
        background-color: #00D98B;
        position: absolute;
        top: 0;
        right: 0;
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;