.wrapper {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    :global {
        .btn.ant-btn {
            width: 100%;
        }
    }
}
.cancelButton {
    &:active, &:hover, &:focus {
        background: transparent;
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;