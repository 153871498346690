.wrapper {
    display: flex;
    align-content: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 24px;
}

.titleBlock {
    display: flex;
    gap: 12px;
    align-items: center;
}

.title {
    color: var(--text-primary);
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
}

.templatesCountBlock {
    line-height: 22px;
    color: var(--text-secondary);
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;