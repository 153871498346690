textarea.input, .input {
    font-size: 18px;
    line-height: 26px;
    
    padding: 10px;
    background: var(--input-bg);
    
    border: var(--input-border);
    border-radius: 8px;

    input {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        background-color: transparent;
        color: var(--input-color);

        &::placeholder {
            color: #858585;
        }
    }
    :global {
        .anticon.ant-input-clear-icon {
            color: var(--text-primary);
        }
        .anticon.ant-input-clear-icon {
            background: url('../../../Assets/Icons/Clear/Clear.svg') 50% 50% no-repeat;
        }
        .anticon.ant-input-clear-icon > svg {
            color: transparent;
        }
    }
}

.input {
    &:hover {
        border: var(--input-active-border);
    }
    &:focus-within {
        border: var(--input-active-border);
        box-shadow: none;
    }
}

input {
    &:hover {
        border: var(--input-active-border);
    }
}

textarea.input {
    padding: 11px 12px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    border: var(--input-border);
    &::placeholder {
        color: #858585;
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;