.wrapper {
    height: 100%;
    color: var(--text-primary);
    overflow-y: auto;
    flex-direction: column;
    align-items: center;
    width: 100%;
    display: flex;
    .content {
        max-width: 100%;
        padding: 30px 24px;
        width: 712px;       
        border-radius: 16px;  
        background: var(--component-bg); 
        display: flex;
        gap: 32px;
        flex-direction: column;
    }
}

@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;