.wrapper {
    margin-bottom: 16px;
    :global {
        .ant-breadcrumb > ol > li a {
            color: var(--text-secondary);
            &:hover {
                color: var(--electric-green);
            }
        }
        .ant-breadcrumb > ol > li:last-child span {
            color: var(--text-primary);
        }
        .ant-breadcrumb-separator {
            color: var(--text-secondary);
        }
    }
}

.hidden {
    display: none;
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;