.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: @xs){
        padding: 48px 16px
    }
    @media screen and (min-width: @md) {
        padding: 56px 32px;
    }
    @media screen and (min-width: @lg) {
        padding: 80px 20px;
    }
}

.content {
    max-width: 1176px;
    width: 100%;
    margin: 0 auto;
}

.mainBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    margin-bottom: 16px;
    font-style: normal;
    color: #2C2E34;
    text-align: center;
    @media screen and (min-width: @xs){
        font-weight: 700;
        font-size: 28px;
        line-height: 32px;
    }
    @media screen and (min-width: @md) {
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
    }
    @media screen and (min-width: @lg) {
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
    }
}


.mainDesc {
    font-style: normal;
    display: flex;
    align-items: center;
    text-align: center;
    color: #2C2E34;
    text-align: center;
    margin-bottom: 56px;
    @media screen and (min-width: @xs){
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
    @media screen and (min-width: @md) {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
    }
    @media screen and (min-width: @lg) {
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
    }
}

.subBlock {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .img {
        width: 30%;
        height: auto;
        @media screen and (min-width: @xs){
            width: 70%;
            margin-top: 32px;
            margin-bottom: 48px;
        }
        @media screen and (min-width: @md) {
            width: 30%;
        }
        @media screen and (min-width: @lg) {
            width: 30%;
        }
    }
    @media screen and (min-width: @xs){
        flex-direction: column;
    }
    @media screen and (min-width: @md) {
        flex-direction: row;
        &:nth-child(odd) {
            flex-direction: row-reverse;
        }
    }
    @media screen and (min-width: @lg) {
        flex-direction: row;
        &:nth-child(odd) {
            flex-direction: row-reverse;
        }
    }
}

.subtitle {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #2C2E34;
    margin-bottom: 16px;
}

.subDesc {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #858585;
}

.textBlock {
    @media screen and (min-width: @xs){
        width: auto;
    }
    @media screen and (min-width: @md) {
        width: 40%;
    }
    @media screen and (min-width: @lg) {
        width: 40%;
    }
}


@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;