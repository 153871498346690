.btn-icon {
    &.ant-btn {
        border-radius: 8px;
        font-family: 'SourceSansPro';
        display: flex;
        align-items: center;
        padding: 6px;
        height: 32px;
        color: var(--text-primary);
        box-shadow: none;
        &-sm {
            padding: 4px;
            height: 24px;
        }
        &-lg {
            height: 40px;
        }
        &-primary {
            color: var(--white);
            background: linear-gradient(180deg, #0BC180 -38.84%, #057C52 126.77%);
            border: transparent;
            justify-content: center;
            &:not(disabled) {
                &:hover {
                    border: transparent;
                    background: linear-gradient(180deg, #1D9569 -25.89%, #025B3B 123.21%);
                    color: var(--white);
                }
            }
            &[disabled], &[disabled]:hover, &[disabled]:focus, &[disabled]:active {
                background: rgba(255, 255, 255, 0.08);
                border: transparent;
                color: rgba(255, 255, 255, 0.2);
            }
        }

        &-ghost {
            color: var(--text-primary);
            background: transparent;
            border: transparent;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            justify-content: center;
            &:not(disabled) {
                &:hover {
                    border: transparent;
                    color: var(--text-primary);
                    background: var(--button-ghost-hover);
                }
            }
            &[disabled], &[disabled]:hover, &[disabled]:focus, &[disabled]:active {
                background: rgba(255, 255, 255, 0.08);
                border: transparent;
                color: rgba(255, 255, 255, 0.2);
            }
        }

    }
    &_secondary {
        color: var(--text-primary);
        background: rgba(255, 255, 255, 0.05);
        border: transparent;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        justify-content: center;
        &:not(disabled) {
            &:hover {
                border: transparent;
                color: var(--text-primary);
                background: rgba(254, 254, 254, 0.1);
            }
        }
        &[disabled], &[disabled]:hover, &[disabled]:focus, &[disabled]:active {
            background: var(--component-bg);
            border: transparent;
            color: rgba(255, 255, 255, 0.2);
        }
    }

    &_stroke {
        color: var(--text-primary);
        background: transparent;
        border: 1px solid #FEFEFE;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        justify-content: center;
        &:not(disabled) {
            &:hover {
                border: 1px solid #00CA81;
                color: #00CA81;
                background: transparent;
            }
        }
        &[disabled], &[disabled]:hover, &[disabled]:focus, &[disabled]:active {
            background: rgba(255, 255, 255, 0.08);
            border: 1px solid rgba(255, 255, 255, 0.2);
            color: rgba(255, 255, 255, 0.2);
        }
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;