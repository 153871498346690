.wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    color: #767779;
}

.icon {
    margin-right: 8px;
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;