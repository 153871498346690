.wrapper {
    display: flex;
    background-color: var(--component-bg);
    padding: 8px 16px;
    border-radius: 8px;
    justify-content: space-between;
    height: 48px;
    gap: 24px;
    border: 1px solid var(--component-bg);
    .content {
        display: flex;
        gap: 10px;
    }
    div {
        display: flex;
        align-items: center;
    }
}

.search {
    :global {
        .ant-input-affix-wrapper {
            padding: 4px 11px;
        }
    }

    width: 35%;
    max-width: 400px;
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;