.wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 24px;
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;