.modal {
    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: var(--medium-gray); 
    }
    :global {
        .ant-btn {
            width: 100%;
        }
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;