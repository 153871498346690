.wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background: transparent;
}

.text {
    font-size: 20px;
    color: var(--text-secondary);
    text-align: center;
}

.spinner {
    font-size: 31px;
    display: flex;
    margin-top: 24px;
    color: @primary-color;
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;