.text {
    margin-top: 32px;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: var(--text-secondary);
}
.wrapper {
    max-width: 432px;
}
.img {
    width: 100%;
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;