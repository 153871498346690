.badge {
    :global {
        .ant-badge-status {
            &-dot {
                width: 8px;
                height: 8px;
                margin: 0 4px;
            }
            &-text {
                margin-left: 0; 
            }
        }
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;