button.switch {
    background: #858585;
    &:global {
        &.ant-switch-checked {
            background: @primary-color;
            &:focus {
                box-shadow: none;
            }
        }
    }
}

@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;