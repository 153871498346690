.wrapper {
    min-width: 248px;
    min-height: 258px;
    display: flex;
    flex-direction: column;
    background: var(--card-background);
    border: var(--card-border);
    border-radius: 16px;
    padding: 16px;
    &:hover {
        background: var(--card-bg-hover);
        cursor: pointer;
    }
    .header {
        .rightSide {
            .rate {
                color: var(--text-primary);
                display: flex;
                align-items: center;
            }
        }
    }
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    .rightSide {
        display: flex;
        align-items: center;
        gap: 5px;
    }
}

.icon {
    width: 32px;
    height: 32px;
    border-radius: 8px;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--white);
}

.date {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: var(--text-secondary);
    margin-right: 16px;
}

.title {
    margin-bottom: 16px;
}

.menu {
    display: flex;
    justify-content: center;
    align-items: center;
}

.desc {
    display: flex;
    height: 100px;
    margin-bottom: 20px;
    white-space: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    overflow: hidden;
    color: var(--text-secondary);
}

.info {
    display: flex;
    justify-content: space-between;
    .text {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: var(--text-primary);
    }
    .value {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: var(--text-primary);
    }
}



.buttonMenu {
    min-width: 272px;
    svg {
        margin-right: 16px;
    }
}

.modalWrapper {
    .modalText {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: var(--text-secondary);
        padding-bottom: 24px;
    }
    .modalButton {
        width: 100%;
        height: 40px;
        &:first-child {
            margin-bottom: 16px;
        }
    }
}

@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;