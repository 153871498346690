.grid {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    .info {
        height: 56px;
        border-radius: 8px;
        background-color: var(--component-secondary-bg);
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        gap: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--text-primary);
        .buttonInfo {
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--info-icon-bg);
            width: 16px;
            height: 16px;
        }
    }
    .lastFormItem {
        margin-bottom: 0;
    }
    .buttonStroke {
        width: 100%;
        &:active, &:focus {
            background: transparent;
        }
    }
    .finishButton {
        width: 100%;
        margin-bottom: 8px;
    }
    .finishDesc {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--text-secondary);
        margin-bottom: 24px;
    }
    label {
        width: 100%;
    }
    :global {
        .ant-form .ant-form-item .ant-form-item-label label {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: var(--text-primary);
            margin-top: 10px;
        }
        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
            box-shadow: none;
        }
        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
            border-color: #00D98B;
        }
        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
            border-color: #00D98B;
        }
        .ant-radio-inner {
            background-color: transparent;
        }
        .ant-radio-button-wrapper:not(:first-child)::before {
            display: none;
        }
    }
}
.select {
    :global {
        div.ant-select-selector {
            .ant-select-selection-item {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #080808;
                padding: 0 8px;
                border: 1px solid #B3B3B3;
                border-radius: 8px;
            }
            .ant-select-selection-item, .ant-select-selection-placeholder {
                font-size: 16px;
                line-height: 24px;
                padding: 0 8px;
            }
            .ant-select-selection-overflow {
                padding: 0 8px;
                flex-wrap: nowrap;
                .ant-select-selection-overflow-item-suffix {
                    left: unset !important;
                    position: relative !important;
                }
            }
        }
    }
}

.buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .backButton, .button {
        width: 188px;
    }
}

.controlBlock {
    display: flex;
    flex-direction: column;
}

.label {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;