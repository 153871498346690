.description {
    overflow: hidden;
    max-height: 72px;
    height: 72px;
    padding: 12px 16px;
    border-radius: 8px;
    border: var(--template-description-border);
    background: var(--template-description-bg);
    color: var(--template-description-color);
    font-size: 16px;
}
.descriptionContent {
    height: 100%;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 12px;
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background-clip: padding-box;
        border-radius: 8px;
        width: 6px;
        border: 3px solid transparent;
        background-color: #B3B3B3;
    }
    &::-webkit-scrollbar-track {
        border-radius: 8px;
        background-color: transparent;
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;