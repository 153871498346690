.input {
    width: 100%;
}

.anonymization {
    color: var(--text-primary);
    padding: 12px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    &Header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &Cancel {
        color: #ff9898;
        font-size: 12px;
        &:hover {
            cursor: pointer;
        }
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;