.column() {
    padding: 50px 130px;
}

.wrapper {
    display: flex;  
    min-height: 100vh; 
}
.leftCol {
    min-width: 40%;
    max-width: 40%;
    background: var(--background);
    box-shadow: 4px 4px 32px rgba(7, 25, 19, 0.24);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .column();
}
.rightCol {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--component-bg);
    .column();
}
.content {
    max-width: 400px;
    width: 100%;
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;