.wrapper {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.content {
  max-width: 560px;
}
.link {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.title {
  margin-top: 15px;
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: 900;
  color: var(--electric-green);
}
.desc {
  font-size: 20px;
  color: #828282;
}

@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;