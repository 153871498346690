.wrapper {
    background: #2C2E34;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: @xs){
        padding: 48px 16px;
    }
    @media screen and (min-width: @md) {
        padding: 56px 32px;
    }
    @media screen and (min-width: @lg) {
        padding: 80px 20px;
    }
}

.content {
    max-width: 1176px;
    width: 100%;
    margin: auto;
}

.title {
    text-align: center;
    color: #FEFEFE;
    margin-bottom: 8px;
    @media screen and (min-width: @xs){
        font-weight: 700;
        font-size: 28px;
        line-height: 32px;
    }
    @media screen and (min-width: @md) {
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
    }
    @media screen and (min-width: @lg) {
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
    }
}

.desc {
    font-style: normal;
    text-align: center;
    color: #858585;
    margin-bottom: 56px;
    @media screen and (min-width: @xs){
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
    @media screen and (min-width: @md) {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
    }
    @media screen and (min-width: @lg) {
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
    }
}

.block {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    background: rgba(254, 254, 254, 0.1);
    border-radius: 16px;
    ul {
        padding-left: 20px;
        li {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #FEFEFE;
            opacity: 0.5;
            &:first-child {
                margin-bottom: 16px;
            }
        }
    }
}

.subTitle {
    margin-top: 24px;
    margin-bottom: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #FEFEFE;
}

.row {
    .col {
        @media screen and (min-width: @xs){
            display: block;
            flex: 0 0 100%;
            max-width: 100%;
        }
        @media screen and (min-width: @md) {
            display: block;
            flex: 0 0 50%;
            max-width: 50%;
        }
        @media screen and (min-width: @lg) {
            display: block;
            flex: 0 0 33.33333333%;
            max-width: 33.33333333%;
        }
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;