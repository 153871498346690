.wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.subHeader {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
}

.statistics {
    display: flex;
    flex-direction: column;
    gap: 24px;
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;