.modal {
    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: var(--medium-gray); 
    }
    :global {
        .ant-btn {
            margin-top: 24px;
            width: 100%;
        }
    }
    .divider {
        border-top: 1px solid #FFFFFF4F;
    }
    .info,
    .subInfo {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .info {
        font-size: 16px;
        color: var(--primary-color);
    }
    .subInfo {
        margin-top: 16px;
        color: var(--medium-gray);
        font-size: 14px;
    }
    .inputNumber {
        max-width: 160px;
        :global {
            .ant-input {
                padding: 0;
            }
            .ant-btn {
                margin: 0;
                height: 100%;
            }
        }
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;