.backLink { 
    padding-right: 0 !important;
    padding-left: 0 !important;
    width: 32px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    &:hover {
        svg path {
            stroke: rgba(0, 202, 129);
        }
    }
    svg {
        transform: rotate(180deg);
        path {
            transition: 0.3s;
        }
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;