.title {
    margin-bottom: 8px;
    color: var(--text-primary);
    padding: 12px;
    background: var(--chosentext-bg);
    border-radius: 12px;
}

.value {
    color: var(--text-primary);
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;