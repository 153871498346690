.nameCol {
    display: flex;
    flex-direction: row;
    color: var(--text-secondary);
    justify-content: space-between;
    &Arrow {
        color: #797979;
        font-size: 14px;
    }
}

.statusColumn {
    width: 30px;
    padding: 4px 10px;
    justify-content: center;
    align-items: center;
    :global{
        .ant-table-filter-column {
            margin-left: -5px;
        } 
        .ant-table-filter-trigger {
            margin: 0;
        }
    }
}

.rate {
    color: var(--text-primary);
    display: flex;
    align-items: center;
}

.titleColumn {
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;