.wrapper {
    background: #FEFEFE;
    color: #2C2E34;
    font-family: 'SourceSansPro';
}

.header {
    padding: 0;
    height: 56px;
    position: fixed;
    z-index: 1;
    width: 100%;
    background: #F8F8F8;
    display: flex;
    justify-content: center;
}

.footer {
    padding: 0;
    height: 360px;
    width: 100%;
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;