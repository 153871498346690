.custom {
    :global {
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            border-radius: 0;
        }
        div.ant-select-selector {
            border-radius: 8px !important;
            min-height: 48px;
            max-height: 110px;
            overflow-y: auto;
            &::-webkit-scrollbar {
                width: 12px;
                background-color: transparent;
            }
            &::-webkit-scrollbar-thumb {
                background-clip: padding-box;
                border-radius: 8px;
                width: 6px;
                border: 3px solid transparent;
                background-color: #B3B3B3;
            }
            &::-webkit-scrollbar-track {
                border-radius: 8px;
                background-color: transparent;
            }
            // display: none ломает логику antd - видно на перезагрузке
            .ant-select-selection-item[title='Выбрать все'] {
                opacity: 0;
                width: 0;
                margin: 0;
                padding: 0;
            }
            .ant-select-selection-item {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #080808;
                padding: 0 8px;
                border: 1px solid #B3B3B3;
                border-radius: 8px;
            }
            .ant-select-selection-item, .ant-select-selection-placeholder {
                font-size: 16px;
                line-height: 24px;
                padding: 0 8px;
                display: flex;
                align-items: center;
            }
            .ant-select-selection-placeholder {
                color: #858585;
            }
            .ant-select-selection-overflow {
                padding: 0 8px;
                // flex-wrap: nowrap;
                .ant-select-selection-overflow-item-suffix {
                    left: unset !important;
                    position: relative !important;
                }
            }
        }
    }
}

// GLOBAL 
:global {
    .ant-select-tree .ant-select-tree-node-content-wrapper {
        height: 100%;
        padding: 0 12px 0 8px;
        .ant-select-tree-title {
            height: 100%;
            display: flex;
            align-items: center;
        }
        &:hover {
            background-color: transparent;
        }
    }
    .ant-select-tree .ant-select-tree-treenode {
        align-items: center;
        padding: 0;
    }
    .ant-select-tree-checkbox {
        margin: 0;
        height: 100%;
        display: flex;
        padding-top: 2px;
        align-items: center;
        &::before {
            content: '';
            width: 12px;
            display: block;
            position: relative;
        }
        &::after {
            border: none;
        }
        .ant-select-tree-checkbox-inner {
            display: flex;
            align-items: center;
        }
    }
    .ant-tree-select-dropdown {
        padding: 4px 0;
    }
    .ant-select-tree-treenode {
        display: flex;
        height: 48px;
        background: #FEFEFE;
        border-radius: 8px;
        align-items: center;
        font-size: 16px;
        line-height: 24px;
        padding: 5px;
        &:hover {
            background-color: #f5f5f5;
        }
        .ant-select-tree.ant-select-tree-treenode.ant-select-tree-treenode-switcher-close {
            padding:  5px 0 !important;
        }
        .ant-select-tree-switcher.ant-select-tree-switcher-noop {
            width: 0;
        }
        .ant-select-tree .ant-select-tree-node-content-wrapper {
            padding: 0;
        }
    }
    .ant-select-tree-list-scrollbar {
        right: 4px !important;
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;