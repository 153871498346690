.wrapper {
    overflow: hidden;
    box-sizing: border-box;
    width: 100%;
}

.title {
    text-align: center;
    margin-bottom: 40px;
}
.button {
    width: 206px;
    max-width: 206px;
    justify-content: center;
    align-self: center;
}
.controls {
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}
.input {
    height: 48px;
}

.verifySuccess {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    gap: 16px;
}

.verifyError {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: var(--red);
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;