.wrapper {
    height: 100%;
    display: flex;
    flex-direction: row;
}


.iframeWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #858585;
    padding: 40px 30px 0 30px;
    position: relative;
}

.workIframeWrapper {
    height: 100%;
    position: relative;
    max-width: 780px;
    width: 100%;
}

.workIframe {
    background-color: var(--white);
    width: 780px;
    max-width: 780px;
    width: 100%;
}

.originalIframe {
    display: none;
}

.iFrameLoader {
    background-color: var(--white);
    width: calc(100% - 60px);
    max-width: 780px;
    position: absolute;
    margin: 0 auto;
    margin-top: 40px;
    height: 100%;
}

@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;