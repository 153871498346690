.statusColumn {
    width: 30px;
    padding: 4px 10px;
    justify-content: center;
    align-items: center;
    :global{
        .ant-table-filter-column {
            margin-left: -5px;
        } 
        .ant-table-filter-trigger {
            margin: 0;
        }
    }
}

.anonStatusTitle {
    display: flex;
}

.titleColumn, .titleColumn span {
    display: block;
    max-width: 400px;
    width: min-content;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    :global .ant-tooltip-open {
        display: block;
        max-width: 400px;
        width: min-content;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;