.wrapper {
    background: var(--bottombar-bg);
    padding: 4px 32px;
}

.row {
    &:global {
        & > .ant-col {
            display: flex;
            align-items: center;
        }
    }
}

@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;