.templateMode {
    :global {
        .ant-select-selector .ant-select-selection-item .ant-select-selection-item-remove {
            display: none;
        }
        .ant-select-tree .ant-select-tree-treenode .ant-select-tree-checkbox.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
            background-color: var(--solid-gray);
            background: var(--solid-gray);
            border: 1px solid var(--solid-gray);

        }
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;