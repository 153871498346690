.wrapper {
    display: flex;
    gap: 12px;
    justify-content: flex-end;
    :global {
        .ant-btn .anticon {
            width: 14px;
            height: 14px;
        }
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;