.wrapper {
    height: 100%;
    .blocks {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
    }
}


@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;