.radio {
    background-color: var(--component-bg);
    padding: 4px 8px;
    border-radius: 8px;

    .radioButton {
        background-color: var(--component-bg);
        font-style: normal;
        font-weight: 600;
        color: var(--text-primary);
        border: none;
        border-radius: 6px;
        padding: 0 8px;
        .icon {
            display: flex;
            height: 100%;
            align-items: center;
        }
        &:focus-within {
            box-shadow: unset;
        }
        &:first-child {
            border-left: 0px;
            border-radius: 6px;
        }
        &:last-child {
            border-radius: 6px;
        }
        &:not(:first-child)::before {
            display: none;
        }
        &:not(:last-child) {
            margin-right: 4px;
        }
    
    }

    :global {
        .ant-radio-button-wrapper {
            font-weight: 400;
            &:hover {
                color: #0BC180;
            }
        }

        .ant-radio-button-wrapper-checked {
            background: linear-gradient(180deg, #0BC180 -38.84%, #057C52 126.77%);
            color: var(--white);
            font-weight: 400;
            &:hover {
                background: linear-gradient(180deg, #1D9569 -25.89%, #025B3B 123.21%);
                color: var(--white);
            }
        }
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;