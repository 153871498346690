.modal {
    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: var(--medium-gray); 
    }
    .content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-height: 600px;
        overflow: auto;
        .invite {
            display: flex;
            justify-content: space-between;
            gap: 8px;
        }
        .email {
            width: 100%;
            hyphens: auto;
            word-wrap: break-word;
            word-break: break-all;
        }
        .cancel {
            width: min-content;
        }
    }
}

@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;