.error {
    display: flex;
    gap: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: var(--text-primary);
    padding: 8px;
    :global {
        .anticon {
            padding-top: 4px;
        }
    }
}

.content {
    height: 100%;
    .title {
        display: flex;
        color: var(--text-primary);
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        gap: 10px;
        padding: 8px;
        justify-content: space-between;
        .count {
            width: 24px;
            height: 24px;
            border: 1px solid var(--text-primary);
            border-radius: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
        }
    }

    .emptyDesc {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: var(--text-primary);
        padding: 8px;
    }

    .menu {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        overflow-x: hidden;
        height: 228px;
        &::-webkit-scrollbar {
            background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background-clip: padding-box;
            border-radius: 10px;
            border: 4px solid transparent;
            background-color: #B3B3B3;
        }
        &::-webkit-scrollbar-track {
            border-radius: 10px;
            background-color: transparent;
        }
        &Item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 40px;
            border-radius: 4px;
            &:hover {
                background-color: var(--button-secondary-hover);
            }
            .menuItemButtonGroup {
                display: flex;
                align-items: center;
                &Button {
                    padding: 4px 8px;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--electric-green);
                    &[disabled], &[disabled]:hover, &[disabled]:focus, &[disabled]:active {
                        color: var(--button-disabled-text);
                    }
                }
                &ButtonError {
                    &[disabled], &[disabled]:hover, &[disabled]:focus, &[disabled]:active {
                        color: var(--red);
                    }
                }
            }

            &ActionButtons {
                display: flex;
                gap: 12px;
                &Button {
                    &:not(disabled) {
                        &:hover {
                            color: var(--electric-green);
                        }
                    }
                    &[disabled], &[disabled]:hover, &[disabled]:focus, &[disabled]:active {
                        color: var(--button-disabled-text);
                    }
                }
            }
        }
    }
}

.hintContent {
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    gap: 8px;
    .hintTitle {
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
    }
    .hintError {
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: var(--red);
        text-align: center;
    }
    .hintInfo {
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;