.countStat {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 16px;
    background: var(--component-bg);
    border: 1px solid var(--component-bg);
    border-radius: 16px;
    height: 212px;
    width: 20%;
    overflow: hidden;
    &:not(:last-child) {
        margin-right: 16px;
    }
    .iconTitle {
        .icon {
            margin-bottom: 8px;
        }
        .title {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: var(--text-secondary);
            margin-bottom: 8px;
        }
    }
    .info {
        .count, .zeroCount {
            font-weight: 700;
            font-size: 40px;
            line-height: 48px;
            color: var(--text-primary);
            margin-bottom: 4px;
        }
        .zeroCount {
            color: var(--text-secondary);
        }
        .subTitle {
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            height: 24px;
            color: #858585;
        }
    }

}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;