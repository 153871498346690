.application {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-style: normal;
    position: relative;
    background: var(--background);
    color: var(--text-primary);
}

@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;