.popover {
    span {
        color: var(--secondary-element-color);
    }
    .button {
        width: 32px;
        height: 32px;
        &:hover, &:active, &:focus {
            background: var(--component-bg);
            
        }
        &:active, &:focus {
            border: 1px solid #00CA81 !important;
        }
    }
}

@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;