.wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    gap: 12px;
    font-size: 20px;
    padding: 30px;
    color: #ff6363;
}

.icon {
    font-size: 28px;
}

.button {
    :global {
        .btn.ant-btn {
            font-size: 20px;
            padding: 20px;
            font-family: inherit;
        }
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;