.switchModeBar {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    background: var(--bottombar-bg);
    padding: 40px 8px;
}

.controlButtons {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 24px;
    margin-bottom: 32px;
    align-items: center;
}

.hint {
    margin-top: 32px;
    display: none;
}

.hintVisible {
    display: block;
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;