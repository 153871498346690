.button {
    display: flex;
    flex-shrink: 1;
    width: 20px;
    height: 20px;
    :global {
        svg {
            width: 20px;
            height: 20px;
        }
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;