.cookiePolitics {
    max-width: 1280px;
    * {
        text-align: justify;
        color: black;
    }
    a {
        color: var(--electric-green);
    }
    p, h2, table, tbody {
        padding-bottom: 12px;
        margin-bottom: 0;
    }
    hr + p {
        padding-top: 12px;
        margin-bottom: 0;
    }
    h2 {
        font-size: inherit;
        font-weight: bold;
    }
    ol {
        padding-left: 18px;
        margin-bottom: 12px;
    }
    table tbody tr td {
        padding-bottom: 12px;
    }
    ol li,
    table tbody tr td:last-child {
        padding-left: 12px;
    }
}

@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;