.active :global svg,
.inactive :global svg {
    padding: 3px;
    border-radius: 50%;
    max-width: 16px;
    max-height: 16px;
    margin: 0 0 6px 6px;
    & path {
        fill: var(--white);
    }
}

.active :global svg {
    background-color: #00CA81;
}

.inactive :global svg {
    background-color: #F86262;
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;