.error {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
	min-height: 250px;
    :global {
        .ant-empty {
			display: flex;
			flex-direction: column;
			max-width: 250px;
			margin-top: -30px;
			&-image {
				height: min-content;
			}
			&-description {
				color: var(--text-secondary);
			}
        }
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;