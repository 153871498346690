.radio {
    padding: 4px;
    border-radius: 29px;
    background-color: var(--component-bg);
    height: 46px;
    display: flex;
    width: fit-content;
    margin: 0 auto;
    .radioButton {
        color: var(--text-primary);
        font-size: 14px;
        line-height: 20px;
        border: none;
        border-radius: 26px;
        background-color: transparent;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 168px;
        .radioDisabled {
            color: var(--text-secondary);
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
        }
        .icon {
            display: flex;
            height: 100%;
            align-items: center;
        }
        &:focus-within {
            box-shadow: unset;
        }
        &:not(:first-child)::before {
            display: none;
        }
        &:not(:last-child) {
            margin-right: 4px;
        }
    }

    :global {
        .ant-radio-button-wrapper {
            font-weight: 600;
            &:hover {
                background-color: var(--component-bg-hover);
            }
        }

        .ant-radio-button-wrapper-checked,
        .ant-radio-button-wrapper-checked:hover {
            background: rgba(0, 202, 129, 1);
            color: var(--white);
        }

        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
            z-index: 0;
        }
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;