.card {
    padding: 16px 16px 24px 16px;
    border: var(--last-block-border);
    border-radius: 16px;
    background: var(--last-block-bg);
    height: 336px;
    &Info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: var(--text-primary);
    }
    &Title {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
    }
    &Link {
        cursor: pointer;
        transition: 0.3s;
        display: flex;
        align-items: center;
        text-align: center;
        gap: 10px;
        padding: 0 10px 0 0;   
        height: 24px;
        span {
            font-size: 14px;
            line-height:16px;
            
        }
        &:hover {
            color: #00CA81;
            svg path {
                stroke: #00CA81; 
            }
        }
        .svg {
            display: flex;
            path{
                // transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            }
        }
    }
    &Content {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin-top: 16px;
        gap: 10px;
        height: 95%;
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;