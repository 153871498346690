.table {
    :global {
    .ant-table.ant-table-small .ant-table-expanded-row-fixed {
        margin-right: -16px;
    }
    .ant-table {
        &-body{
            &::-webkit-scrollbar-track-piece {
                &:end{
                    margin-bottom: 8px; 
                }
                &:start{
                    margin-top: 4px;
                }
            }
        }
        table {
            font-size: 16px;
            border-spacing: 0 8px;
            margin: -24px 0 0 0;
        }
        background: transparent;
        .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
            content: none;
        }
        .ant-table-column-title {
            flex: unset;
        }
        .ant-table-column-title > .ant-table-column-sorters > .ant-table-column-title {
            margin-right: 16px;
        }
        .ant-table-filter-column {
            justify-content: flex-start;
        }
        .ant-table-filter-trigger {
            margin: 0 0 0 8px;
        }
        .ant-table-thead {
            tr {
                th {
                    padding-top: 16px;
                    background: transparent;
                    color: var(--text-primary);
                    font-weight: 400;
                    font-size: 14px;
                    border-bottom: none;
                    &:first-child {
                        padding-left: 14px;
                    }
                    .ant-table-column-sorters {
                        justify-content: flex-start;
                        .ant-table-column-title {
                            flex: unset;
                        }
                    }
                }
            }
            .ant-table-cell.ant-table-cell-scrollbar {
                display: none;
            }
        }
        .ant-table-tbody {

            tr.ant-table-row-selected > td {
                background: var(--table-row-select);
            }
            td.ant-table-column-sort {
                background: transparent;
            }
            tr {
                background: var(--tasks-table-row-bg);
                height: 64px;
                border-radius: 8px;
                &:hover {
                    cursor: pointer;
                    td {
                        background: var(--tasks-table-row-hover-bg);
                    }
                }
                td {
                    border-bottom: none;
                    margin: 8px 0;
                    color: var(--text-primary);
                    position: relative;
                    padding: 4px 8px;
                    border-spacing: 0 10px;
                    &.ant-table-cell-row-hover {
                        background-color: inherit;
                    }
                    &:first-child {
                            overflow: hidden;
                            color: var(--text-secondary);
                            border-top-left-radius: 8px;
                            border-bottom-left-radius: 8px;
                            padding-left: 16px;
                    }
                    &:last-child {
                            overflow: hidden;
                            border-top-right-radius: 10px;
                            border-bottom-right-radius: 10px;
                            width: 50px;
                            &::after {
                                display: none;
                            }
                    }
                }
            }
        }
        }
        .ant-table-tbody:before {
            content:"@";
            display:block;
            line-height:4px;
            text-indent:-99999px;
        }
        .ant-table-filter-trigger:hover {
            color: var(--text-primary);
        }
    }
}



@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;