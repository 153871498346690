.ScrollToStartButton {
    position: absolute;
    cursor: pointer;
    z-index: 1;
    color: var(--text-secondary)
}

.Scrollbars {
    bottom: 2%;
    right: 2%;
}

.Iframe {
    bottom:2%;
    right: -5%;
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;