.wrapper {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    gap: 12px;
    font-size: 18px;
    padding: 30px;
    color: #ffd2d2;
}

.backButton {
    :global {
        .btn.ant-btn-ghost {
            width: 188px;
            font-size: 18px;
            font-family: inherit;
        }
    }
}   
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;