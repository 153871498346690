.grid {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    :global {
        .ant-form .ant-form-item .ant-form-item-label label {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: var(--text-primary);
        }
        .ant-radio-inner {
            background-color: transparent;
        }
    }
}

.radioGroup {
    display: flex;
    flex-direction: column;
    .radio {
        color: var(--text-secondary);
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
}

.title {
    margin-bottom: 30px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-secondary);
}
.button {
    width: 100%;
}

.steps {
    display: flex;
    margin: 24px 0;
    .line {
        height: 4px;
        width: 24px;
        background: #00D98B;
        border-radius: 6px;
        margin-right: 8px;
    }
  
    .dot {
        width: 4px;
        height: 4px;
        background: #B3B3B3;
        border-radius: 6px;
        margin-right: 8px;
    }
}

.select {
    :global {
        div.ant-select-selector {
            padding: 0;
            .ant-select-selection-overflow {
                padding: 0 10px;
                flex-wrap: nowrap;
            }
            .ant-select-selection-item {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #080808;
                border: 1px solid #B3B3B3;
                border-radius: 8px;
            }
            .ant-select-selection-item, .ant-select-selection-placeholder {
                font-size: 16px;
                line-height: 24px;
                padding: 0 8px;
            }
        }
    }
}
.lastFormItem {
    margin-bottom: 0px;
}

.inputProjectDescription {
    :global {
        .ant-input-textarea {
            padding: 0px;
            border: none;
        }
        .ant-input {
            box-shadow: none;
            border-radius: 8px;
            &:focus, &:active, &:hover {
                box-shadow: 0 0 0 2px rgba(0, 217, 139, 0.2);
                border-color: #25e698;
                border: 1px solid #25e698;
            }
        }
        .ant-input-textarea-show-count::after {
            color: var(--text-primary);
            opacity: 0.5;
            margin-bottom: 0;
            font-size: 14px;
        }
        textarea {
            &::-webkit-scrollbar {
                background-color: transparent;
            }
            &::-webkit-scrollbar-thumb {
                background-clip: padding-box;
                border-radius: 10px;
                border: 3px solid transparent;
                background-color: #B3B3B3;
            }
            &::-webkit-scrollbar-track {
                border-radius: 10px;
                background-color: transparent;
            }
        }
    }
}


@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;