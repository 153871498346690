.wrapper {
    .form {
        .buttons {
            display: flex;
            flex-direction: row;
            .button {
                margin-right: 16px;
            }
        }
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;