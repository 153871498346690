.common {
    color: var(--text-primary);
}
.h1 {
    font-weight: bold;
    font-size: 40px;
    line-height: 120%;
}
.h2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
}
.h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;