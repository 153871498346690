.wrapper {
    background: var(--toptoolbar-bg);
    padding: 4px 48px;
    min-height: 48px;
    display: flex;
    border-bottom: 1px solid rgba(179, 179, 179, 0.3);
    width: 100%;
    :global {
        .ant-row-space-between {
            width: 100%;
        }
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;