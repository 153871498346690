.wrapper {
    box-sizing: border-box;
    width: 100%;
}
.title {
    text-align: center;
    margin-bottom: 40px;
}
.button {
    width: 288px;
    max-width: 288px;
    justify-content: center;
    align-self: center;
}
.controls {
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}
.input {
    height: 48px;
}
.errorText {
    text-align: center;
    color: red;
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;