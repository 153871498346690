.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 24px;
    .firstLine {
        display: flex;
        margin-bottom: 24px;
    }
    .secondLine {
        display: flex;
        .bar {
            padding: 16px 24px 24px;
            background: var(--component-bg);
            border: 1px solid var(--component-bg);
            border-radius: 16px;
            height: 212px;
            width: 80%;
            .title {
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 0;
                color: var(--text-secondary);
            }
        }
    }
    .pieStat {
        padding: 16px 24px;
        gap: 8px;background: var(--component-bg);
        border: 1px solid var(--component-bg);
        border-radius: 16px;
        height: 212px;
        .title {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: var(--text-primary);
        }
        .pie {
            display: flex;
            height: 88%;
            align-items: center;
            justify-content: flex-start;
        }
    }
    .subInfo {
        display: flex;
        justify-content: space-between;
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;