.privacyPolicy {
    max-width: 1280px;

    * {
        text-align: justify;
        color: black;
    }
    a {
        color: var(--electric-green);
    }
    h1 {
        text-align: center;
    }
    p {
        margin-bottom: 0;
        & > span {
            display: block;
            text-indent: 60px;
        }
    }
    .subTitle {
        display: flex;
        justify-content: center;
        padding: 12px 0;
        font-weight: bold;
        span {
            color: var(--brand-blue);
        }
        span:last-child {
            padding-left: 30px;
            text-align: center;
        }
    }
    .element {
        span:first-child {
            display: inline-block;
            width: 60px;
        }
    }
    .elementWithTopPadding {
        padding-top: 12px;
    }
    ol, ul {
        padding-left: 18px;
        margin: 0;
    }
    ol li {
        padding-left: 12px;
    }
    .listWithLeftCustomPadding {
        padding-left: 78px;
    }
}

@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;