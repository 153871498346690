.invite {
    display: flex;
    justify-content: space-between;
    gap: 8px;
}
.email {
    width: 100%;
    hyphens: auto;
    word-wrap: break-word;
    word-break: break-all;
}
.cancel {
    width: min-content;
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;