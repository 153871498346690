.menu {
    background: var(--component-bg);
    padding: 8px;
    mix-blend-mode: normal;
    box-shadow: 0px 0px 24px 24px rgba(0, 0, 0, 0.5);
    border-radius: 8px;

    .item {
        font-size: 14px;
        line-height: 16px;
        color: var(--text-primary);
        padding: 10px 12px;
        position: relative;
        
        &:hover {
            background: unset;
            color: @primary-color;
        }
    }
    :global {
        .ant-dropdown-menu-item-selected, .ant-dropdown-menu-submenu-title-selected {
            background-color: rgba(230,255,241, 0.5);
        }
    }
}

@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;