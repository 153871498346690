.wrapper {
    :global {
        .ant-form-item-label {
            > label {
                color: var(--text-secondary);
                &.ant-form-item-required {
                    &:not(.ant-form-item-required-mark-optional) {
                        &::before {
                            content: unset;
                        }
                        &::after {
                            display: inline-block;
                            margin-right: 4px;
                            color: #00D98B;
                            font-size: 14px;
                            line-height: 1;
                            content: '*';
                        }
                    }
                }
            }
        }
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;