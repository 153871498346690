.wrapper {
    position: fixed;
    width: 80%;
    left: 50%;
    transform: translate(-50%, -20%);
    z-index: 200;
    bottom: 25px;
    display: flex;
    justify-content: center;
}
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1280px;
}

@media (min-width: 320px) {
    .wrapper {
        padding: 0px 16px;
    }
}
@media (min-width: 768px) {
    .wrapper {
        padding: 0px 28px;
    }
}
@media (min-width: 1200px) {
    .wrapper {
        padding: 0px 32px;
    }
}

.control {
    display: flex;
    justify-content: center;
    align-items: center;
}

.text {
    margin: 0;
    color: #A2A8B1;
    font-size: 14px;
    line-height: 18px;
}

.frame {
    width: 100%;
    display: flex;
    border-radius: 16px;
    align-items: center;   
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0 0 2px #A2A8B1;
}

@media screen and (min-width: 320px) {
    .frame {
        gap: 10px;
        flex-direction: column;
        padding: 12px;
    }
}

@media screen and (min-width: 768px) {
    .frame {
        gap: 16px;
        flex-direction: row;
        padding: 18px 16px;
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;