.wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    max-width: 880px;
}

.corepageStyles {
    margin: 0 auto;
    min-width: 880px;
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;