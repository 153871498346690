.wrapper {
    background-color: var(--component-bg);
    border: var(--component-border);
    border-radius: 16px;
    padding: 24px;
    width: 50%;
    .title {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 24px;
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;