.wrapper {
    background-color: rgba(254, 254, 254, 0.1);
    border-radius: 16px;
    padding: 24px 20px;
    display: flex;
    flex-direction: column;
    border: 1px solid transparent;
    font-family: 'SourceSansPro';
    @media screen and (min-width: @xs){
        max-width: none;
    }
    @media screen and (min-width: @md) {
        max-width: none;
    }
    @media screen and (min-width: @lg) {
        max-width: 279px;
    }
    &:hover {
        border: 1px solid rgba(0, 202, 129, 1);
    }
    .title {
        color: #FEFEFE;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 8px;
    }
    .info {
        height: 104px;
        margin-bottom: 24px;
        .type {
            color: #FFFFFF;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
        }
        .users {
            color: rgba(255, 255, 255, 0.6);
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 24px;
        }
        .price {
            .oldPrice {
                color: rgba(255, 255, 255, 0.6);
                font-weight: 600;
                font-size: 28px;
                line-height: 32px;
                text-decoration-line: line-through;
                margin-right: 16px;
            }
            .newPrice {
                color: #FEFEFE;
                font-weight: 600;
                font-size: 28px;
                line-height: 32px;
            }
            &Month {
                color: rgba(255, 255, 255, 0.6);
                font-weight: 600;
                font-size: 16px;
                line-height: 16px;
            }
        }
    }

    .button {
        width: 100%;
        margin-bottom: 24px;
    }
    .divider {
        border-top: 1px solid rgba(255, 255, 255, 0.31);
        margin-bottom: 24px;
    }
    .options {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .optionBlock {
            display: flex;
            .img {
                margin: 4px 8px 0 0;
            }
            .option {
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: #fff;
            }
        }

    }
}

@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;