.wrapper {
    background: #F8F8F8;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (min-width: @xs){
        padding: 48px 16px;
    }
    @media screen and (min-width: @md) {
        padding: 56px 32px;
    }
    @media screen and (min-width: @lg) {
        padding: 80px 132px;
    }
}

.content {
    max-width: 1176px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    text-align: center;
    color: #080808;
    margin-bottom: 56px;
    @media screen and (min-width: @xs){
        font-weight: 700;
        font-size: 28px;
        line-height: 32px;
    }
    @media screen and (min-width: @md) {
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
    }
    @media screen and (min-width: @lg) {
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
    }
}
.img {
    width: 100%;
    @media screen and (min-width: @xs){
        display: none;
    }
    @media screen and (min-width: @md) {
        display: block;
    }
    @media screen and (min-width: @lg) {
        display: block;
    }
}

.mobileImg {
    @media screen and (min-width: @xs){
        display: block;
    }
    @media screen and (min-width: @md) {
        display: none;
    }
    @media screen and (min-width: @lg) {
        display: none;
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;