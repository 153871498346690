@import url('./Assets/fonts/index.css');

:root {
    // Brand Solids
    --electric-green: #00CA81;
    --electric-green-light: #00D98B;
    --electric-green-hover: #007B4F;
    --red: #F86262;
    --light-red: #FEE0E0;

    // Black and White Solids
    --light-green: #ECF6EE;
    --white: #FEFEFE;
    --ultra-light-gray: #F8F8F8;
    --light-gray: #E5E5E5;
    --medium-gray: #B3B3B3;
    --solid-gray: #858585;
    --dark-grafit: #1E2024;
    --solid-grafit: #383A3E;
    --black: #080808;

    // SberID Button
    --sber-button-bg: #21A038;
    --sber-button-bg-hover: #1d8b31;
    --sber-button-content-color: #FFF;

    //Brand Gradients
    --gradient-green: linear-gradient(180deg, #0BC180 -38.84%, #057C52 126.77%);
    --gradient-green-hover: linear-gradient(180deg, #1D9569 -25.89%, #025B3B 123.21%);
    --gradient-gray: linear-gradient(135deg, #3B3F44 13%, #2E3036 87.5%);
    --gradient-gray-hover: linear-gradient(135deg, #4B5057 5%, #37383E 97%);
    --gradient-light-gray: linear-gradient(135deg, #5C5E61 13%, #41434A 87.5%);
    --gradient-grafit: linear-gradient(135deg, #23252B 13%, #23252B 87.5%);

    //Main
    --background: #F5F5F6;
    --text-primary: var(--black);
    --text-secondary: rgba(8, 8, 8, 0.6);

    --sidebar-chosen-menu-bg: rgba(0, 0, 0, 0.05);

    //Components
    --component-bg: var(--white);
    --component-bg-hover: var(--light-gray);
    --component-border: 1px solid transparent;
    --component-secondary-bg: rgba(166, 166, 166, 0.08);
    --component-secondary-border: 1px solid transparent;
    --component-secondary-bg-hover: var(--medium-gray);

    //Card
    --card-background: var(--white);
    --card-bg-hover: rgba(159, 159, 159, 0.12);
    --card-border: 1px solid transparent;

    --secondary-element-color: rgba(8, 8, 8, 0.6);

    //LastBlock
    --last-block-bg: #FEFEFE;
    --last-block-border: 1px solid transparent;
    --last-block-item-bg: #FFF;
    --last-block-item-border: 1px solid rgba(56, 58, 62, 0.1);

    //Buttons
    --button-secondary: var(--white);
    --button-secondary-hover: rgba(0, 0, 0, 0.12);
    --button-disabled: rgba(0, 0, 0, 0.03);
    --button-disabled-text: rgba(8, 8, 8, 0.3);
    --button-disabled-border: 1px solid rgba(8, 8, 8, 0.3);

    --button-stroke: transparent;
    --button-stroke-border: 1px solid rgba(0, 0, 0, 0.2);
    --button-stroke-hover-border: 1px solid var(--electric-green);
    --button-stroke-hover-text: var(--electric-green);

    --button-stroke-disabled-bg: rgba(0, 0, 0, 0.03);
    --button-stroke-disabled-border: 1px solid rgba(0, 0, 0, 0.10);
    --button-stroke-disabled-color: rgba(8, 8, 8, 0.30);

    --button-ghost-hover: rgba(0, 0, 0, 0.12);
    
    //Input
    --input-bg: var(--white);
    --input-color: var(--black);
    --input-border: 1px solid rgba(0, 0, 0, 0.2);
    --input-active-border: 1px solid var(--electric-green);
    --input-error-border: 1px solid var(--red);

    //Pagination
    --pagination-border: 1px solid rgba(0, 0, 0, 0.2);


    //Table
    --table-row-select: rgba(143, 143, 143, 0.2);
    --table-row-hover: rgba(143, 143, 143, 0.06);


    //Project buttons
    --project-buttons-bg: var(--white);
    --project-buttons-hover: rgba(159, 159, 159, 0.12);
    --project-button-border: 1px solid rgba(56, 58, 62, 0.1);

    //Donut
    --donut-label-fill: rgba(8, 8, 8, 0.7);
    --donut-fill: #B3B3B3;
    --donut-empty-fill: rgb(238, 239, 238);
    

    //Chart
    --chart-label-fill: rgba(8, 8, 8, 0.9);
    --chart-label-stroke: rgba(0, 0, 0, 0.2);
    --chart-empty-fill: rgb(238, 239, 238);

    //Tasks table
    --tasks-table-row-bg: var(--white);
    --tasks-table-row-border: 1px solid rgba(56, 58, 62, 0.1);
    --tasks-table-row-hover-bg: rgb(234, 234, 235);
    --tasks-table-row-checked-border: 1px solid #00CA81;
    
    //Toptoolbar
    --toptoolbar-bg: var(--white);

    //Bottom bar
    --bottombar-bg: #F8F8F8;

    //Tag

    --process-tag-bg: rgba(179, 179, 179, 0.06);
    --process-tag-border: 1px solid rgba(179, 179, 179, 0.30);
    --process-tag-color: rgba(8, 8, 8, 0.90);

    --error-tag-bg: rgba(248, 98, 98, 0.06);
    --error-tag-border: 1px solid rgba(248, 98, 98, 0.50);
    --error-tag-color: #F86262;

    --success-tag-bg: rgba(0, 217, 139, 0.06);
    --success-tag-border: 1px solid rgba(0, 202, 129, 0.30);
    --success-tag-color: #007B4F;

    //Anonymization
    --chosentext-bg: rgba(159, 159, 159, 0.12);

    //Tooltip
    --tooltip-bg: var(--solid-grafit);
    --tooltip-color: var(--white);

    //Sidebar
    --sidebar-btn-hover: rgba(0, 0, 0, 0.05);
    //Hint
    --hint-icon-bg: var(--electric-green);

    //Info
    --info-icon-bg: var(--solid-gray);

    //TemplateDescription 
    --template-description-border: 1px solid rgba(0, 0, 0, 0.2);
    --template-description-color: #858585;
    --template-description-bg: #FEFEFE;

    //Tabs
    --table-tabs-name-count-bg: rgba(0, 217, 139, 0.06);
    --table-tabs-name-count-color: #00CA81;
    --table-tabs-name-color: #080808;

    //Document 
    --document-background: #F5F5F6;

    //Tariff
    --tariff-primary-color: var(--black);
    --tariff-second-color: var(--medium-gray); 
}


[data-theme='dark'] {

    //Main
    --background: var(--dark-grafit);
    --text-primary: var(--white);
    --text-secondary: rgba(255, 255, 255, 0.6);

    //Components
    --component-bg: #2C2E33;
    --component-bg-hover: rgba(0, 0, 0, 0.12);
    --component-border: 1px solid transparent;

    --secondary-element-color: rgba(254, 254, 254, 0.6);

    --sidebar-chosen-menu-bg: rgba(254, 254, 254, 0.1);

    //Card
    --card-background: #2C2E33;
    --card-bg-hover: #383A3E;
    --card-border: 1px solid transparent;
    
    //LastBlock
    --last-block-bg: #2C2E33;
    --last-block-border: 1px solid transparent;
    --last-block-item-bg: rgba(255, 255, 255, 0.06);
    --last-block-item-border: 1px solid rgba(255, 255, 255, 0.1);
    
    //Buttons
    --button-secondary: #2C2E33;
    --button-secondary-hover: rgba(254, 254, 254, 0.1);
    --button-disabled: rgba(255, 255, 255, 0.03);
    --button-disabled-text: rgba(255, 255, 255, 0.2);
    --button-disabled-border: 1px solid rgba(255, 255, 255, 0.2);
    
    --button-stroke-border: 1px solid rgba(254, 254, 254, 0.2);
    --button-stroke-hover-border: 1px solid var(--electric-green);
    --button-stroke-hover-text: var(--electric-green);
    --button-stroke-disabled-bg: rgba(255, 255, 255, 0.10);
    --button-stroke-disabled-border: 1px solid rgba(255, 255, 255, 0.20);
    --button-stroke-disabled-color: rgba(255, 255, 255, 0.30);

    --button-ghost-hover: rgba(254, 254, 254, 0.1);

    //Input
    --input-bg: var(--white);
    --input-color: var(--black);
    --input-border: 1px solid rgba(255, 255, 255, 0.2);

    //Pagination
    --pagination-border: 1px solid rgba(254, 254, 254, 0.2);

    --project-buttons-bg: rgba(255, 255, 255, 0.03);
    --project-buttons-hover: rgba(255, 255, 255, 0.1);
    --project-button-border: 1px solid rgba(255, 255, 255, 0.1);

    //Donut
    --donut-label-fill: #FEFEFE;
    --donut-fill: #B3B3B3;
    --donut-empty-fill: rgba(0, 0, 0, 0.2);
    
    //Chart
    --chart-label-fill: #FEFEFE;
    --chart-label-stroke: #FEFEFE;
    --chart-empty-fill: rgba(0, 0, 0, 0.2);

    //Tasks table
    --tasks-table-row-bg: rgba(255, 255, 255, 0.06);
    --tasks-table-row-border: 1px solid rgba(255, 255, 255, 0.1);
    --tasks-table-row-hover-bg: rgba(255, 255, 255, 0.1);
    --tasks-table-row-checked-border: 1px solid #00CA81;

    //Top bar
    --toptoolbar-bg: #2C2E33;

    //Bottom bar
    --bottombar-bg: #1E2024;

    //Tag

    --process-tag-bg: rgba(179, 179, 179, 0.10);
    --process-tag-border: 1px solid rgba(179, 179, 179, 0.30);
    --process-tag-color: #B3B3B3;

    --error-tag-bg: rgba(248, 98, 98, 0.10);
    --error-tag-border: 1px solid rgba(248, 98, 98, 0.50);
    --error-tag-color: #F86262;

    --success-tag-bg: rgba(0, 217, 139, 0.06);
    --success-tag-border: 1px solid rgba(0, 202, 129, 0.30);
    --success-tag-color: #007B4F;

    //Anonymization
    --chosentext-bg: rgba(255, 255, 255, 0.1);

    //Tooltip
    --tooltip-bg: var(--white);
    --tooltip-color: rgba(8, 8, 8, 0.9);

    //Sidebar
    --sidebar-btn-hover: rgba(254, 254, 254, 0.1);
    
    //Hint
    --hint-icon-bg: var(--solid-gray);

    //Info
    --info-icon-bg: var(--electric-green);

    //TemplateDescription 
    --template-description-border: 1px solid rgba(255, 255, 255, 0.20);
    --template-description-color: rgba(255, 255, 255, 0.20);
    --template-description-bg: rgba(254, 254, 254, 0.08);

    //Tabs
    --table-tabs-name-count-bg: rgba(0, 217, 139, 0.06);
    --table-tabs-name-count-color: #00CA81;
    --table-tabs-name-color: #FEFEFE;
    --table-tabs-name-color-active: #00CA81;

    //Document 
    --document-background: var(--solid-gray);

    //Tariff
    --tariff-primary-color: var(--white);
    --tariff-second-color: rgba(255, 255, 255, 0.6);
}

body {
    margin: 0;
    font-family: 'SourceSansPro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    //Переопределение стилей в antd
    ::selection {
        color: #080808;
        background: #CEE2FC;
    }
    .ant-layout {
        background: transparent;
    }
    .ant-tooltip-inner {
        display: flex;
        align-items: center;
        color: #000000;
        border-radius: 8px;
        border: 1px solid #FFFFFF;
        box-shadow: 0px 4px 8px rgba(69, 71, 79, 0.18);
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        padding: 8px;
    }
    // TODO если нотификейшн покажет себя хорошо - удалить!
    .ant-notification-notice  {
        background: transparent;
        padding: 0;
    }
    .ant-notification-notice-message  {
        line-height: 24px;
    }
    .ant-notification-notice-description {
        font-weight: 500;
        line-height: 24px;
        padding-right: 24px;
    }
    .ant-notification-notice-content {
        background: linear-gradient(135deg, #3B3F44 13%, #2E3036 87.5%);
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.5);
        border-radius: 8px;
        padding: 16px 24px;
        font-weight: bold;
        .ant-notification-error {
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: var(--text-primary);
            .anticon {
                color: #F86262;
            }
        }
        .ant-notification-info {
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: var(--text-primary);
            .anticon {
                color: #00D98B;
            }
        }
        .ant-notification-success {
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: var(--text-primary);
            .anticon {
                color: #00D98B;
            }
        }
    }
    .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper-focused {
        border-right-width: 2px !important;
    }
    .ant-popover-arrow {
        display: none;
    }
    .ant-popover-inner {
        background: var(--component-bg);
        padding: 8px;
        mix-blend-mode: normal;
        box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.5);
        border-radius: 8px;
    }
    .ant-popover-inner-content {
        padding: 0;
    }
    .ant-avatar-group .ant-avatar {
        border: 1px solid #B3B3B3;
    }
    .ant-avatar-group .ant-avatar:not(:first-child) {
        margin-left: -5px;
    }
    .ant-popconfirm {
        .ant-popover-inner {
            padding: 16px;
        }
        .ant-popover-message-title {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--text-primary);
        }
        .ant-btn {
            border: var(--button-stroke-border);
            border-radius: 4px;
            background-color: transparent;
            color: var(--text-primary);
            &:hover {
                border: 1px solid #00D98B;
                color: #00D98B;
            }
        }
        .ant-btn-primary {
            background: var(--electric-green);
            border-radius: 4px;
            color: var(--white);
            border: transparent;
            &:hover {
                background: var(--electric-green-hover);
                border: transparent;
                color: var(--white);
            }
        }

    }
    .ant-popover-message > .anticon {
        color: #00D98B;
    }
    .ant-popover-message {
        padding-bottom: 16px;
    }
    .ant-table-filter-dropdown {
        background-color: transparent; 
    }
    .ant-table-body{
        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
            background-color: inherit;
            &-thumb{
                background-color: grey;
                border-radius: 20px;
            }
            &-track{
                background-color: inherit;
            }

        }  
        &::-webkit-scrollbar-corner{
            background-color: inherit;
        }
    }

    .ant-notification-notice {
        width: fit-content;
        min-width: 280px;
        max-width: 380px;
        .ant-notification-notice-content {
            background: #FEFEFE;
            padding: 18px;
            border-radius: 8px;
            font-weight: bold;
            .ant-notification-notice-icon {
                height: 26px;
                margin-top: 20px;
                top: 0;
                left:20px;
                bottom: 0;
                right: auto; 
            }
        }
    }
    .ant-notification-notice-close {
        height: 20px;
        margin-top: 20px;
        top: 0;
        left: auto;
        bottom: 0;
        right: 20px;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;