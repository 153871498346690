.modal {
    :global {
        form button {
            width: 100%;
        }
        .ant-form-item:first-child {
            margin-bottom: 32px;
        }
        .ant-form-item:last-child {
            margin-bottom: 0;
        }
    }
}

@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;