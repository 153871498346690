.dropDown {
    :global {
        .ant-checkbox-wrapper + .ant-checkbox-wrapper {
            margin-left: 0px;
        }
    }
    background-color: var(--component-bg);
    padding: 14px 0 14px 16px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: 3px;
        &-thumb {
            background-color: grey;
            border-radius: 10px;
            width: 3px;
        }
    }  
    &::-webkit-scrollbar-track-piece {
        &:end {
            margin-bottom: 10px; 
        }
        &:start {
            margin-top: 10px;
        }
    }
    & > div > div {
        display: flex;
        flex-direction: column;
        gap: 20px;
        & label {
            display: flex;
            gap: 10px;
            margin-right: 10px;
            color: var(--text-primary);
        }
    }
}

.button {
    :global {
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {   
            background-color: inherit;
            border: 1px solid transparent;
            border-radius: 8px;
            box-shadow: none;
        }
        .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
            .ant-select-selector{
                border: 1px solid var(--component-bg) ;
                box-shadow: none;
                outline: none;
            }
        }
        .ant-select-selection-placeholder {
            color: var(--text-primary);
        }
        .ant-select-arrow {
            color: var(--text-primary);
            display: flex;
            font-size: 11px;
            align-items: flex-end;
            transition: 0.3s;
        }
    }
}

.buttonActive {
    :global {
        .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
            .ant-select-selector {
                background-color: rgba(254, 254, 254, 0.1);
                border: 1px solid #858585;
            }
            .ant-select-arrow {
                transform: rotate(180deg);
            }
        }
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;