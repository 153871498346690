.item {
    font-family: "OpenSans";
    display: flex;
    background: var(--last-block-item-bg);
    border: var( --last-block-item-border);
    border-radius: 16px;
    padding: 12px 16px;
    gap: 10px;
    height: 28%;
    width: 49.4%;
    justify-content: space-between;
    align-items: center;
    transition: 0.3s;
    min-width: 0;
    cursor: pointer;
    &Content {
        width: calc(100% - 95px);
        display: flex;
        justify-content: space-between;
    }
    &NameBlock {  
        width: 70%;  
        text-overflow: ellipsis;
        display: flex;
        flex-direction: column;
        max-width: max-content;
        gap: 6px;
        justify-content: center;
    }
    &CoreTitle {  
        white-space:nowrap;
        overflow: hidden; 
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: var(--text-primary);
        text-overflow: ellipsis;
    }
    &SubTitle {
        font-size: 12px;
        line-height: 16px;
        color: var(--text-secondary);
    }
    &TypeBlock {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 8px;
        text-overflow: ellipsis;
        width: 30%;
        min-width: 95px;
        padding-left: 8px;
    }
    &DateBlock {
        min-width: 85px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 8px;
    }
    &Type {
        color: var(--text-primary);
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &Info {
        color: var(--text-primary);
    }
    &:hover {
        background: var(--project-buttons-hover);
    }
}

.Tags {
    display: flex;
    gap: 4px;
    font-size: 12px;
    .processTag, .errorTag, .successTag {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        padding: 2px 4px;
        margin-right: 0;
        line-height: 16px;
        font-weight: 600;
    }
    .processTag {
        background: var(--process-tag-bg);
        border: var(--process-tag-border);
        color: var(--process-tag-color);
    }
    .errorTag {
        background: var(--error-tag-bg);
        border: var(--error-tag-border);
        color: var(--error-tag-color);
    }
    .successTag {
        background: var(--success-tag-bg);
        border: var(--success-tag-border);
        color: var(--success-tag-color);
    }
}

.toolTip {
    max-width: none;
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;