.wrapper {
    display: flex;
    margin-top: 20px;
    gap: 20px;
    justify-content: space-between;
    @media screen and (max-width: 1300px){
        flex-wrap: wrap;
    }
    article {
        min-width: 280px;
        padding: 24px;
        background-color: var(--component-bg);
        border-radius: 16px;
        @media screen and (max-width: 1300px){
            width: calc(50% - 10px);
            min-width: auto;
        }
    }
    article p {
        font-size: 16px;
    }
    article span {
        font-size: 28px;
        font-weight: 600;
        line-height: 32px;
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;