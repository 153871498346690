.wrapper {
    transition: all .2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        -webkit-transform: scale(1.2, 1.2);
        transform: scale(1.2, 1.2);
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;