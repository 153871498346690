.wrapper {
    background: #2C2E34;
    height: 360px;
    padding: 56px 20px 32px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (min-width: @xs){
        height: 975px;
    }
    @media screen and (min-width: @lg) {
        height: 440px;
    }
    
    .content {
        max-width: 1176px;
        width: 100%;
        height: 360px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media screen and (min-width: @xs){
            height: 780px;
        }
        @media screen and (min-width: @md) {
            height: 360px;
        }
        @media screen and (min-width: @lg) {
            height: 360px;
        }
        .copy {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;

            color: #858585;

            opacity: 0.5;
            align-self: center;
        }
        .rightCol {
            display: flex;
            flex-direction: column;
            @media screen and (min-width: @xs){
                width: 100%;
            }
            @media screen and (min-width: @lg) {
                width: unset;
            }
            .title {
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
                color: #FFFFFF;
                margin-top: 56px;
                margin-bottom: 16px;
                @media screen and (min-width: @xs){
                    text-align: center;
                }
                // @media screen and (min-width: @md) {
                //     text-align: unset;
                // }
                @media screen and (min-width: @lg) {
                    text-align: unset;
                }
            }
            .desc {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #B3B3B3;
                margin-bottom: 32px;
                @media screen and (min-width: @xs){
                    text-align: center;
                }
                @media screen and (min-width: @lg) {
                    text-align: unset;
                }
            }
            .sendEmail {
                display: flex;
                .input {
                    padding: 12px 16px;
                    width: 346px;
                    height: 40px;
                    background: #FEFEFE;
                    border-radius: 8px;
                    margin-right: 16px;
                }
                .button {
                    padding: 8px 16px;
                    width: 102px;
                    height: 40px;
                    background: linear-gradient(180deg, #00D98B -38.84%, #15885F 133.04%);
                    border-radius: 8px;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 24px;
                    color: #FEFEFE;
                    border: 1px transparent;
                }
            }
            .links {
                display: flex;
                flex-direction: row;
                border-bottom: 1px solid #858585;
                gap: 16px;
                padding-bottom: 24px;
                @media screen and (min-width: @xs){
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
                @media screen and (min-width: @lg) {
                    flex-direction: row;
                    justify-content: unset;
                    align-items: unset;
                }
                a {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 24px;
                    color: #B3B3B3;
                }
                .link:hover {
                    color: #FEFEFE;
                }
                .link::after {
                    content: '';
                    display: block;
                    width: 40px;
                    border-bottom: 2px solid transparent;
                }
                .link:hover:after {
                    content: '';
                    display: block;
                    width: 40px;
                    border-bottom: 2px solid #00D98B;
                }
            }
            .documents {
                display: flex;
                flex-direction: row;
                gap: 16px;
                padding-top: 24px;
                @media screen and (min-width: @xs){
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
                @media screen and (min-width: @lg) {
                    flex-direction: row;
                    justify-content: unset;
                    align-items: unset;
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                }
                a {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 24px;
                    color: #B3B3B3;
                }
                .link:hover {
                    color: #FEFEFE;
                }
            }
            .contacts {
                display: flex;
                @media screen and (min-width: @xs){
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
                @media screen and (min-width: @lg) {
                    flex-direction: row;
                    justify-content: unset;
                    align-items: unset;
                }
                a {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #E5E5E5;
                    margin-right: 40px;
                    @media screen and (min-width: @xs){
                        margin-right: 0;
                        margin-bottom: 24px;
                        &:last-child {
                            margin-bottom: 56px;
                        }
                    }
                    @media screen and (min-width: @lg) {
                        margin-right: 40px;
                    }
                }
            }
            .lawDown {
                display: flex;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                color: #858585;
                flex-direction: column;
                align-self: center;
                .demo {
                    text-align: justify;
                }
                @media screen and (min-width: @xs){
                    display: flex;
                }
                @media screen and (min-width: @lg) {
                    display: none;
                }
                div {
                    margin-bottom: 16px;
                }
            }
        }
    }

    
}

.row {
    display: flex;
    justify-content: space-between;
}

.leftCol {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: @xs){
        width: 100%;
    }
    @media screen and (min-width: @lg) {
        width: unset;
    }
    .legalInfo {
        display: flex;
        flex-direction: column;
        color: #FEFEFE;
        @media screen and (min-width: @xs){
            justify-content: center;
            align-items: center;
        }
        @media screen and (min-width: @lg) {
            justify-content: unset;
            align-items: unset;
        }
        .img {
            max-height: 24px;
            max-width: 54px;
            margin-bottom: 22px;
        }
        .pravoLogo {
            max-height: 22px;
            max-width: 155px;
            margin-bottom: 48px;
        }
        .law {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #858585;
            @media screen and (min-width: @xs){
                display: none;
            }
            @media screen and (min-width: @lg) {
                display: block;
            }
            div {
                margin-bottom: 16px;
            }
        }
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;