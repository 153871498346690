.select {
    :global {
        div.ant-select-selector {
            &:not(.ant-select-customize-input) {
                height: 48px;
                font-size: 16px;
                line-height: 26px;
                color: hsl(220, 3%, 21%);
                border: 1px solid #D0D7DD;
                background: #FEFEFE;
                border-radius: 8px;
            }
            .ant-select-selection-item {
                color: #353638;
            }
            .ant-select-selection-overflow {
                font-size: 16px;
                line-height: 26px;
                padding: 10px 0px;
            }
            .ant-select-selection-item, .ant-select-selection-placeholder {
                font-size: 16px;
                line-height: 26px;
                padding: 10px 0px;
            }
        }
        .ant-select-selection-placeholder {
            font-size: 16px;
            line-height: 26px;
            padding: 10px 0px;
            color: #858585;
        }
    }
}

:global {
    .ant-select-dropdown {
        border-radius: 8px;
    }
    .ant-select-item-option {
        display: flex;
        height: 48px;
        background: #FEFEFE;
        border-radius: 8px;
        align-items: center;
        font-size: 16px;
        line-height: 24px;
    }
}
@xs: 320px;@sm: 576px;@md: 768px;@lg: 992px;@xl: 1320px;@xxl: 1600px;@primary-color: #00CA81;