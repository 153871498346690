@font-face {
    font-family: 'SourceSansPro';
    src: url('./SourceSansPro/SourceSansPro-ExtraLight.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'SourceSansPro';
    src: url('./SourceSansPro/SourceSansPro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'SourceSansPro';
    src: url('./SourceSansPro/SourceSansPro-Regular.ttf') format('truetype');
    font-weight: 400;
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'SourceSansPro';
    src: url('./SourceSansPro/SourceSansPro-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'SourceSansPro';
    src: url('./SourceSansPro/SourceSansPro-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'SourceSansPro';
    src: url('./SourceSansPro/SourceSansPro-Bold.ttf') format('truetype');
    font-weight: 700;
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: url('./OpenSans/OpenSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'OpenSans';
    src: url('./OpenSans/OpenSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'OpenSans';
    src: url('./OpenSans/OpenSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'OpenSans';
    src: url('./OpenSans/OpenSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'OpenSans';
    src: url('./OpenSans/OpenSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}